import React, { useState, useEffect, Fragment } from 'react';
import {
  Form,
  Button,
  Card,
  Accordion,
  OverlayTrigger,
  Tooltip,
  InputGroup,
} from 'react-bootstrap';
/*import { Trans } from '@lib/i18n'*/
import { putESGAssessment, getEsgCategories } from '@lib/api';
import { ChevronRight, TooltipInfoIcon /* IcoWarning */ } from '@icons';
//import Tooltip from '@lib/tooltip'
//import { useTranslation } from 'react-i18next'
import { SuccessToast, DangerToast } from '@components/GlobalToastList';
import ScrollArrow from '@components/ScrollArrow';
import TutorialsButtonWrapper from 'components/TutorialsButtonWrapper';

const QuestionComponent = ({
  projectStatus,
  questions,
  question,
  questionsWithDependences,
  setQuestionsWithDependences,
  handleAssessmentChange,
  handleChecked,
  handleValue,
  assessment,
  translations,
}) => {
  // console.log('questions', questions);
  // console.log('questionsAss', assessment);

  useEffect(() => {
    let copyOfQuestionsWithDependences = questionsWithDependences; //questionID: [answerID, answerID,]

    //per popolare questionsWithDependences
    questions.forEach((question) => {
      if (question.dependsOnAnswers.length > 0) {
        if (copyOfQuestionsWithDependences[question.id] === undefined) {
          copyOfQuestionsWithDependences[question.id] = {};
        }

        question.dependsOnAnswers.forEach((answerId) => {
          if (copyOfQuestionsWithDependences[question.id].combination === undefined) {
            copyOfQuestionsWithDependences[question.id].combination = [];
          }

          if (!copyOfQuestionsWithDependences[question.id].combination.includes(answerId)) {
            copyOfQuestionsWithDependences[question.id].combination.push(answerId);
          }
        });

        if (copyOfQuestionsWithDependences[question.id].questionDiv === undefined) {
          copyOfQuestionsWithDependences[question.id].questionDiv = [];
        }
        copyOfQuestionsWithDependences[question.id].questionDiv.push(
          document.getElementById(`questionID-${question.id}`)
        );
      }
    });

    if (copyOfQuestionsWithDependences) {
      setQuestionsWithDependences(copyOfQuestionsWithDependences);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTooltip = (props) => (
    <Tooltip id='button-tooltip' {...props}>
      {question.description}
    </Tooltip>
  );

  return (
    <>
      <div id={`questionID-${question.id}`} name={question.id} questionid={question.id}>
        <hr className='hr-question' />
        <div className='row justify-content-around'>
          <div className='col-2'>
            <span className='question-code'>{question.code}</span>
            {/* <b>Q:</b>{question.id} */}
          </div>

          <div className='col-10'>
            {question.text}

            {question.description ? (
              <OverlayTrigger
                placement='top'
                delay={{ show: 200, hide: 400 }}
                overlay={renderTooltip}>
                <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
              </OverlayTrigger>
            ) : null}
          </div>
        </div>

        <div className='row mt-4'>
          <div className='col-2'></div>

          <div className='col-10'>
            {(question.type === 'radio' || question.type === 'checkbox') && (
              <div key={`default-radio-${question.id}`} className='mb-3'>
                {question.answers.map((answer) => (
                  <div className='d-flex gap-4' key={answer.id}>
                    {/* <b>A:</b>{answer.id} */}
                    <div>
                      <Form.Check
                        type={question.type}
                        id={`${answer.id}`}
                        name={`${question.id}`}
                        label={`${answer.text}`}
                        dependsonquestion={`${question.dependsOnQuestion}`}
                        dependsonanswer={`${question.dependsOnAnswers}`}
                        onChange={(e) => handleAssessmentChange(e)}
                        defaultChecked={handleChecked(question.id, answer.id)}
                        disabled={
                          projectStatus.status === 'DELETED' || projectStatus.status === 'SUBMITTED'
                            ? true
                            : false
                        }
                      />
                    </div>

                    <div>
                      {answer.requiresDocument === 1 && answer.text !== 'No'
                        ? assessment
                            .filter((el) => el.answers[0].answerId === answer.id)
                            .map((el) =>
                              el.answers[0].value === 'on' ? (
                                <p
                                  style={{ fontSize: '1rem' }}
                                  key={`${el.answers[0].answerId}${el.answers[0].value}`}
                                  className='m-0'>
                                  {translations
                                    .filter((trans) => trans.code === 'INFOATTACH')
                                    .map((trans) => trans.description)}
                                </p>
                              ) : null
                            )
                        : null}
                    </div>
                  </div>
                ))}
              </div>
            )}

            {question.type === 'input-num' && (
              <div key={`default-input-${question.id}`} className='mb-3'>
                {question.answers.map((answer) => (
                  <Form.Group className='mb-3' key={answer.id}>
                    <Form.Label>{answer.text}</Form.Label>
                    {/* <b>A:</b>{answer.id} */}
                    <Form.Control
                      type='number'
                      id={`${answer.id}`}
                      name={`${question.id}`}
                      defaultValue={handleValue(question.id, answer.id)}
                      dependsonquestion={`${question.dependsOnQuestion}`}
                      dependsonanswer={`${question.dependsOnAnswers}`}
                      onChange={(e) => handleAssessmentChange(e)}
                      style={{ maxWidth: '10rem', textAlign: 'right' }}
                      disabled={
                        projectStatus.status === 'DELETED' || projectStatus.status === 'SUBMITTED'
                          ? true
                          : false
                      }
                    />
                  </Form.Group>
                ))}
              </div>
            )}

            {question.type === 'input-perc' && (
              <div key={`default-input-${question.id}`} className='mb-3'>
                {question.answers.map((answer) => (
                  <Form.Group className='mb-3' key={answer.id}>
                    {/* <b>A:</b>{answer.id} */}
                    <InputGroup>
                      <Form.Control
                        type='number'
                        id={`${answer.id}`}
                        name={`${question.id}`}
                        defaultValue={handleValue(question.id, answer.id)}
                        dependsonquestion={`${question.dependsOnQuestion}`}
                        dependsonanswer={`${question.dependsOnAnswers}`}
                        min='0'
                        max='100'
                        onChange={(e) => handleAssessmentChange(e)}
                        style={{ maxWidth: '10rem', textAlign: 'right' }}
                        disabled={
                          projectStatus.status === 'DELETED' || projectStatus.status === 'SUBMITTED'
                            ? true
                            : false
                        }
                      />
                      <InputGroup.Text className='addon-for-number'>&#37;</InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                ))}
              </div>
            )}

            {question.type === 'input-text' && (
              <div key={`default-input-${question.id}`} className='mb-3'>
                {question.answers.map((answer) => (
                  <Form.Group className='mb-3 pr-5' key={answer.id}>
                    {/* <b>A:</b>{answer.id} */}
                    <Form.Control
                      type='text'
                      id={`${answer.id}`}
                      name={`${question.id}`}
                      defaultValue={handleValue(question.id, answer.id)}
                      dependsonquestion={`${question.dependsOnQuestion}`}
                      dependsonanswer={`${question.dependsOnAnswers}`}
                      onChange={(e) => handleAssessmentChange(e)}
                      maxLength='200'
                      disabled={
                        projectStatus.status === 'DELETED' || projectStatus.status === 'SUBMITTED'
                          ? true
                          : false
                      }
                    />
                  </Form.Group>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const ValutazioneESG = ({ currentProject, translations, containsAzienda = false }) => {
  const [questions, setQuestions] = useState([]);
  const [assessment, setAssessment] = useState([]);
  const [categories, setCategories] = useState([]);
  const [project, setProject] = useState(currentProject);
  const [questionsWithDependences, setQuestionsWithDependences] = useState({});

  // console.log('project', project);
  //   console.log('questions', questions);
  // console.log('assessment', assessment);
  //console.log('questionsWithDependences', questionsWithDependences);

  useEffect(() => {
    getEsgCategories().then((retrievedCategories) => {
      setCategories(retrievedCategories);
    });

    setProject(currentProject);
    setQuestions(currentProject.questions);

    currentProject.esgassessments.forEach((el) => {
      const answers = el.answers.map((answer) => ({
        answerId: Number(answer.id),
        value: answer.value,
      }));
      const questionId = [...new Set(el.answers.map((answ) => answ.questionId))].toString();

      assessment.push({ questionId: Number(questionId), answers: answers });
    });

    setAssessment(assessment.sort((assesA, assesB) => assesA.questionId - assesB.questionId));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject]);

  if (Object.keys(questionsWithDependences).length > 0) {
    Object.keys(questionsWithDependences).forEach((questionId) => {
      const divToHide = document.getElementById(`questionID-${questionId}`);

      if (divToHide) {
        //hide
        divToHide.classList.remove('fade-in-div');
        divToHide.classList.add('hide');

        const name = divToHide.id.replace('questionID-', '');
        const elements = document.querySelectorAll(`[name="${name}"]`);

        elements.forEach((el) => {
          if (el.tagName.toLowerCase() === 'input') {
            el.required = false;
            el.setCustomValidity('');
          }
        });
      }
    });
  }

  if (Object.keys(questionsWithDependences).length > 0 && assessment.length > 0) {
    for (const [questionID, el] of Object.entries(questionsWithDependences)) {
      const isThereCombination = el.combination.every((answID) => {
        return assessment
          .map((asses) => {
            return asses.answers.map((el) => el.answerId);
          })
          .flat()
          .includes(answID);
      });

      if (isThereCombination) {
        //console.log('isThereCombination', `${questionID}: ${el.combination}`, isThereCombination);
        //show
        const divToShow = document.getElementById(`questionID-${questionID}`);

        if (divToShow.classList.contains('hide')) {
          divToShow.classList.remove('hide');
          divToShow.classList.add('fade-in-div');
          divToShow.classList.remove('fade-out-div');
        }
        divToShow.classList.add('fade-in-div');
        divToShow.classList.remove('hide');

        const name = divToShow.id.replace('questionID-', '');
        const elements = document.querySelectorAll(`[name="${name}"]`);

        if (Array.from(elements).some((el) => el.required || el.checked)) {
          elements.forEach((el) => {
            if (el.checked) {
              el.required = true;
            }
          });
        } else {
          elements.forEach((el) => {
            if (el.tagName.toLowerCase() === 'input') {
              el.required = true;

              if (el.type === 'checkbox') {
                el.setCustomValidity(
                  translations.filter((trans) => trans.code === 'ICS107').map((trans) => trans.text)
                );
              }
            }
          });
        }
      }
    }
  }

  useEffect(() => {
    handleChecked();
    handleValue();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessment]);

  const handleChecked = (questionID, answerID) => {
    let boolean = false;

    assessment.forEach((el) => {
      if (Number(el.questionId) === questionID) {
        const checkedAnswers = el.answers.filter((answer) => answer.answerId === answerID);

        if (checkedAnswers.length > 0) {
          boolean = true;
        } else {
          boolean = false;
        }
      }
    });

    return boolean;
  };

  const handleValue = (questionID, answerID) => {
    let value;

    assessment
      .filter((asse) => asse.questionId === questionID)
      .forEach((filteredAsse) => {
        filteredAsse.answers
          .filter((answ) => answ.answerId === answerID)
          .forEach((filteredAnsw) => {
            value = filteredAnsw.value;
          });
      });

    return value;
  };

  const handleHiddenQuestions = (tempState) => {
    setTimeout(() => {
      const hiddenQuestions = [...document.getElementsByClassName('hide')];
      //console.log('hiddenQuestions', hiddenQuestions);

      hiddenQuestions.forEach((el) => {
        const questionId = el.id.replace('questionID-', '');

        const inputsToUncheck = document.getElementsByName(questionId);

        inputsToUncheck.forEach((input) => {
          input.type === 'number' || input.type === 'text'
            ? (input.value = '')
            : (input.checked = false);
        });

        tempState = tempState.filter(
          (tempAsses) => Number(tempAsses.questionId) !== Number(questionId)
        );
        //console.log('tempState dentro', tempState);
      });

      //console.log('tempState fuori', tempState);
      setAssessment(tempState);
    }, 1);
  };

  const handleAssessmentChange = async (e) => {
    //console.log('e.target', e.target);
    const questionID = Number(e.target.name);
    const answerID = Number(e.target.id);
    let tempState = [...assessment];
    //console.log('questionID', questionID);
    //console.log('answerID', answerID);

    switch (e.target.type) {
      case 'radio':
        if (assessment.some((asse) => asse.questionId === questionID)) {
          assessment.forEach((asse, index) => {
            if (asse.questionId === questionID) {
              tempState[index].answers = [{ answerId: answerID, value: e.target.value }];
            }
          });
        } else {
          tempState.push({
            questionId: questionID,
            answers: [{ answerId: answerID, value: e.target.value }],
          });
        }

        break;
      case 'checkbox':
        const checkboxesList = [...document.getElementsByName(questionID)];

        if (e.target.checked) {
          if (assessment.some((asse) => asse.questionId === questionID)) {
            assessment.forEach((asse, index) => {
              if (asse.questionId === questionID) {
                tempState[index].answers.push({ answerId: answerID, value: e.target.value });
              }
            });
          } else {
            tempState.push({
              questionId: questionID,
              answers: [{ answerId: answerID, value: e.target.value }],
            });
          }

          checkboxesList.forEach((checkbox) => {
            if (
              checkbox.tagName.toLowerCase() === 'input' &&
              (Number(checkbox.id) === answerID || (checkbox.required && checkbox.checked))
            ) {
              checkbox.required = true;
              checkbox.setCustomValidity('');
            } else if (
              checkbox.tagName.toLowerCase() === 'input' &&
              Number(checkbox.id) !== answerID
            ) {
              checkbox.required = false;
              checkbox.setCustomValidity('');
            }
          });
        } else {
          assessment.forEach((asse, index) => {
            if (asse.questionId === questionID) {
              if (asse.answers.length > 0) {
                asse.answers.forEach((answ, i) => {
                  if (answ.answerId === answerID) {
                    tempState[index].answers.splice(i, 1);

                    if (tempState[index].answers.length === 0) {
                      tempState.splice(index, 1);
                    }
                  }
                });
              }
            }
          });

          if (checkboxesList.some((div) => div.required)) {
            document.getElementById(answerID).required = false;
          }
        }
        break;
      case 'text':
      case 'number':
        if (e.target.value) {
          if (assessment.some((asse) => asse.questionId === questionID)) {
            assessment.forEach((asse, index) => {
              if (asse.questionId === questionID) {
                if (tempState[index].answers.some((answ) => answ.answerId === answerID)) {
                  tempState[index].answers.forEach((answ, indexAnsw) => {
                    if (answ.answerId === answerID) {
                      tempState[index].answers[indexAnsw] = {
                        answerId: answerID,
                        value: e.target.value,
                      };
                    }
                  });
                } else {
                  tempState[index].answers.push({ answerId: answerID, value: e.target.value });
                }
              }
            });
          } else {
            tempState.push({
              questionId: questionID,
              answers: [{ answerId: answerID, value: e.target.value }],
            });
          }
        } else {
          assessment.forEach((asse, index) => {
            if (asse.questionId === questionID) {
              tempState.splice(index, 1);
            }
          });
        }

        break;
      default:
        console.log('');
    }

    setAssessment(tempState);

    handleHiddenQuestions(tempState);
  };

  const handleSave = (e) => {
    const form = document.getElementById('valutazione-esg-form');
    const inputs = Array.from(form.elements).filter((el) => el.nodeName === 'INPUT');
    const isInvalid = inputs.some((input) => !input.checkValidity());

    if (isInvalid) {
      const accordions = Array.from(form.getElementsByClassName('collapse'));

      accordions.forEach((accordion) => {
        accordion.classList.add('show');
      });

      form.reportValidity();
    } else {
      submit(e);
    }
  };

  const submit = async (e) => {
    e.preventDefault();

    let request = [];

    assessment.forEach((asses) => {
      request.push({
        questionId: Number(asses.questionId),
        answers: asses.answers.map((answ) => ({
          answerId: Number(answ.answerId),
          value: answ.value,
        })),
      });
    });

    //console.log('request submit', request);
    const result = await putESGAssessment(project.id, request);
    //console.log('result submit', result);

    if (result) {
      SuccessToast('Operazione conclusa', 'Progetto modificato correttamente');

      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } else {
      DangerToast('Attenzione', 'Errore nella modifica del progetto');
    }
  };

  const renderTooltip = (props, classWidth) => {
    let message = '';
    //console.log('classWidth', classWidth);
    //console.log('props', props);

    if (props.popper.state) {
      message = props.popper.state.options.testObj;
    }

    return (
      <Tooltip id='button-tooltip' {...props} {...(classWidth ? { className: classWidth } : null)}>
        {message}
      </Tooltip>
    );
  };

  const instructionSection = (
    <div>
      <div className='instructionSection'>
        <p>
          Il <b>questionario ESG</b> fornisce informazioni utili a delineare l’esposizione ai
          fattori ESG - ambientale (E), Sociale (S) e di Governo (G) -, sia del progetto per cui si
          richiede il contributo che del soggetto promotore/proponente.
        </p>
        <p>
          Il questionario, quindi, restituisce una panoramica dell’approccio alla sostenibilità del
          promotore/proponente, in particolare della sua capacità di rispettare pratiche ambientali,
          sociali e di governo - al fine di integrare nelle valutazioni inerenti al suo merito
          creditizio quelle relative ai fattori ESG.
        </p>
        <p>
          Al termine della compilazione è tracciato un profilo attraverso il quale sono analizzati e
          misurati in modo sistemico gli aspetti legati alle questioni ambientali, sociali e di
          governance.
        </p>
        <p>
          Ad ogni fattore viene assegnato un punteggio che fornisce una informazione di sintesi sul
          grado di conformità con le direttive internazionali in materia di sostenibilità,
          sull’impatto ambientale generato dall’attività economica svolta, sul rispetto dei valori
          sociali e sugli aspetti legati alla <i>governance</i> societaria, o sul grado di rischio a
          cui un soggetto è sottoposto in relazione a tali fattori.
        </p>
        <p>
          Il questionario contiene domande raggruppate per fattore ESG, consentendo al rispondente
          di concentrarsi su un fattore alla volta.
        </p>
        <p>
          <u style={{ textDecorationColor: '#c2c923' }}>
            Affinché la valutazione vada a buon fine e risulti possibile inviare il questionario, il
            promotore/proponente deve obbligatoriamente rispondere a tutte le domande.
          </u>
        </p>
        Le domande all’interno del questionario sono formulate seguendo tale impostazione:
        <ul>
          <li>
            per le domande che richiamano la struttura, il rispondente deve fornire una risposta
            facendo riferimento al progetto specifico per il quale si richiede l’erogazione del
            finanziamento da parte di Banca Desio;
          </li>
          <li>
            per le domande che menzionano esclusivamente il promotore/proponente, il rispondente
            deve fornire una risposta facendo riferimento alla sua struttura organizzativa e alle
            sue politiche e regolamenti interni.
          </li>
        </ul>
        <p>
          Le domande provviste di esempi o definizioni utili a una migliore comprensione del quesito
          sono dotate di uno specchietto informativo che si apre ogni volta che il mouse viene
          passato sull’icona recante la lettera <i>i</i>&nbsp;&nbsp;(informazioni).
        </p>
        <p>
          Alle domande in cui viene richiesto il possesso di una certificazione o documentazione, se
          si risponde in modo affermativo, bisogna inviare una copia della documentazione a Banca
          Desio tramite email.
        </p>
      </div>

      <div
        className='my-5'
        style={{ borderTop: '2px solid #c2c923', width: '10%', margin: 'auto' }}></div>

      <div className='mt-4 px-3'>
        <p>
          {translations
            .filter((trans) => trans.code === 'ICS110')
            .map((trans) => trans.description)}
        </p>
        <p>
          {translations
            .filter((trans) => trans.code === 'ICS111')
            .map((trans) => trans.description)}
        </p>
        <p>
          {translations
            .filter((trans) => trans.code === 'ICS112')
            .map((trans) => trans.description)}
        </p>
      </div>
    </div>
  );

  return (
    <>
      <div>
        <div className='simple-box instruction'>
          <div className='row'>
            <div className='col-lg-12'>
              <Accordion className='fade-in-accordion' id='fade-in-accordion'>
                <Accordion.Collapse style={{ marginTop: '2rem' }} eventKey={1} timeout={1000}>
                  <Card.Body>{instructionSection}</Card.Body>
                </Accordion.Collapse>
                <Accordion.Toggle
                  as='h5'
                  className='accordion-title'
                  eventKey={1}
                  style={{ margin: '0' }}>
                  <div className='row p-2'>
                    <div className='col-10 px-3'>
                      <div
                        className='d-flex align-items-center text-uppercase'
                        style={{ height: '100%' }}>
                        Istruzioni
                      </div>
                    </div>
                    <div className='col-2 align-self-center pl-2 px-0 text-center'>
                      <ChevronRight className='accordion-arrow' />
                    </div>
                  </div>
                </Accordion.Toggle>
              </Accordion>
            </div>
          </div>
        </div>

        <h5 className='text-center' style={{ marginTop: '3rem', marginBottom: '3rem' }}>
          Scopri tutte le sezioni del questionario per continuare la tua valutazione.
        </h5>
        {/* <p style={{color: 'grey'}}><IcoWarning style={{width: '20px'}} />&nbsp;tutte le domande sono obbligatorie</p> */}
      </div>
      <Form onSubmit={(e) => handleSave(e)} id='valutazione-esg-form'>
        {categories
          .sort((catA, catB) => catA.order - catB.order)
          .map((category, categoryIndex) => (
            <div className='simple-box' style={{ marginTop: '2.3rem' }} key={category.code}>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='form-login'>
                    <Accordion defaultActiveKey={1}>
                      <Accordion.Collapse
                        style={{ marginTop: '2rem' }}
                        eventKey={categoryIndex + 1}>
                        <Card.Body>
                          {category.esgsubcategories
                            .sort((subcatA, subcatB) => subcatA.order - subcatB.order)
                            .map((subcategory, indexSubcategory) => {
                              return questions.filter(
                                (question) => question.subcategory === subcategory.code
                              ).length > 0 ? (
                                <Fragment key={subcategory.code}>
                                  <Accordion style={{ marginBottom: '1.5rem' }}>
                                    <Accordion.Collapse eventKey={indexSubcategory + 1}>
                                      <Fragment key={indexSubcategory}>
                                        {questions
                                          .filter(
                                            (question) => question.subcategory === subcategory.code
                                          )
                                          .sort((questA, questB) => questA.order - questB.order)
                                          .map((question) => {
                                            return (
                                              <QuestionComponent
                                                key={question.id}
                                                projectStatus={project}
                                                questions={questions}
                                                question={question}
                                                questionsWithDependences={questionsWithDependences}
                                                setQuestionsWithDependences={
                                                  setQuestionsWithDependences
                                                }
                                                handleChecked={handleChecked}
                                                handleValue={handleValue}
                                                handleAssessmentChange={handleAssessmentChange}
                                                assessment={assessment}
                                                translations={translations}
                                              />
                                            );
                                          })}
                                      </Fragment>
                                    </Accordion.Collapse>
                                    <Accordion.Toggle
                                      as='h5'
                                      className='accordion-title'
                                      eventKey={indexSubcategory + 1}
                                      style={{ margin: '0' }}>
                                      <div className='row'>
                                        <div className='col-10 px-3'>
                                          <div
                                            className='d-flex title-simple-box margin-title-box align-items-center'
                                            style={{ height: '100%' }}>
                                            {subcategory.text}
                                            {subcategory.description ? (
                                              <OverlayTrigger
                                                placement='right'
                                                delay={{ show: 200, hide: 400 }}
                                                overlay={renderTooltip}
                                                popperConfig={{ testObj: subcategory.description }}>
                                                <TooltipInfoIcon
                                                  className='tooltip-ico'
                                                  style={{ width: '1rem' }}
                                                />
                                              </OverlayTrigger>
                                            ) : null}
                                          </div>
                                        </div>
                                        <div className='col-2 align-self-center pl-2 py-2 text-center'>
                                          <ChevronRight className='accordion-arrow' />
                                        </div>
                                      </div>
                                    </Accordion.Toggle>
                                  </Accordion>
                                </Fragment>
                              ) : null;
                            })}
                        </Card.Body>
                      </Accordion.Collapse>
                      <Accordion.Toggle
                        as='h3'
                        className='accordion-title'
                        eventKey={categoryIndex + 1}
                        style={{ margin: '0' }}>
                        <div className='row'>
                          <div className='col-10 px-3'>
                            <div className='d-flex align-items-center' style={{ height: '100%' }}>
                              {category.text}
                              {category.description ? (
                                <OverlayTrigger
                                  placement='right'
                                  delay={{ show: 200, hide: 400 }}
                                  overlay={(props) => renderTooltip(props, 'tooltip-maxWith')}
                                  popperConfig={{ testObj: category.description }}>
                                  <TooltipInfoIcon
                                    className='tooltip-ico'
                                    style={{ width: '1rem' }}
                                  />
                                </OverlayTrigger>
                              ) : null}
                            </div>
                          </div>
                          <div className='col-2 align-self-center pl-2 py-2 text-center'>
                            <ChevronRight className='accordion-arrow' />
                          </div>
                        </div>
                      </Accordion.Toggle>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          ))}

        {project.status === 'DRAFT' && (
          <Button
            variant='primary'
            type='button'
            id='submit-button'
            form='valutazione-esg-form'
            className='assessment-button sticky-footer-button'
            onClick={(e) => handleSave(e)}>
            SALVA
          </Button>
        )}
      </Form>
      {/* <TutorialsButtonWrapper
        url={'https://www.youtube.com/embed/SvMp1A6m1y8?si=1waFfI5dJxP7sLf9'}
        videoTitle={'Tutorial Questionario ESG'}
      /> */}

      <ScrollArrow />
    </>
  );
};
export default ValutazioneESG;
