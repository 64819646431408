import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@lib/i18n';



// replace console.* for disable log on 'production'

if (process.env.NODE_ENV === 'production') { //'development' is for local and build

    console.log = () => {}
 
    console.error = () => {}
 
    console.debug = () => {}
 
  }

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


