import React, { useState, useEffect } from 'react';
import * as d3 from 'd3';
import { Button, Modal, OverlayTrigger, Tooltip, Form, Row } from 'react-bootstrap';
//import { Trans, propTrans } from '@lib/i18n';
import { rounder } from '@lib/helpers';
import { Print, ReportPrint } from '@icons';
import { getPercentageESG, getPercentageSROI, putChangeStatusOnSubmitted, logout } from '@lib/api';
import { SuccessToast, DangerToast, InfoToast } from '@components/GlobalToastList';
import LoadingSpinner from '@components/loading-spinner';
import TutorialsButtons from 'components/TutorialsButtons';
import TutorialsButtonWrapper from 'components/TutorialsButtonWrapper';

const stateColorsWheel = {
  onGoing: ['#666666', '#e5e5e5'],
  enough: ['#C2C923', '#e5e5e5'],
  current: ['#1C2743', '#e5e5e5'],
  zero: ['#e5e5e5'],
};

const AnimatedDonutChart = ({ chartId, data, current, progress }) => {
  const plotData = [];

  Object.keys(data).forEach((key) => {
    if (key !== 'total' && key !== 'threshold' && data[key] !== 0) {
      plotData.push(data[key]);
    }
  });

  useEffect(() => {
    let colors = current
      ? stateColorsWheel['current']
      : data.done === 0
      ? stateColorsWheel['zero']
      : data.done >= data.threshold
      ? stateColorsWheel['enough']
      : stateColorsWheel['onGoing']; //colorsWheel[chartId];//colorbrewer(plotData.length);

    /*
      let sizes = {
        innerRadius: 75,
        outerRadius: 100
      };*/

    let sizes = {
      innerRadius: 80,
      outerRadius: 100,
    };

    let durations = {
      entryAnimation: 2000,
    };

    d3.select(`#${chartId}`).html('');

    let generator = d3.pie().padAngle(0.04).sort(null);

    let chart = generator(plotData);

    let arcs = d3
      .select(`#${chartId}`)
      .append('g')
      .attr('transform', 'translate(100, 100)')
      .selectAll('path')
      .data(chart)
      .enter()
      .append('path')
      .style('fill', (d, i) => colors[i])
      .attr('data-value', (d, i) => d.data)
      .attr('data-label', (d, i) => {
        const label = Object.keys(data).find((key) => data[key] === d.data);
        return label;
      });

    let g = d3.select(`#${chartId}`).selectAll('g');

    g.append('text')
      .attr('text-anchor', 'middle')
      .attr('font-size', '2em')
      .attr('y', 12)
      .attr('font-weight', 'bold')
      .text(rounder(data.done) + '%'); //number in circle

    let angleInterpolation = d3.interpolate(generator.startAngle()(), generator.endAngle()());

    let innerRadiusInterpolation = d3.interpolate(0, sizes.innerRadius);
    let outerRadiusInterpolation = d3.interpolate(0, sizes.outerRadius);

    let arc = d3.arc();

    arcs
      .transition()
      .duration(durations.entryAnimation)
      .attrTween('d', (d) => {
        let originalEnd = d.endAngle;
        return (t) => {
          let currentAngle = angleInterpolation(t);
          if (currentAngle < d.startAngle) {
            return '';
          }

          d.endAngle = Math.min(currentAngle, originalEnd);

          return arc(d);
        };
      });

    d3.select(`#${chartId}`)
      .transition()
      .duration(durations.entryAnimation)
      .tween('arcRadii', () => {
        return (t) =>
          arc.innerRadius(innerRadiusInterpolation(t)).outerRadius(outerRadiusInterpolation(t));
      })
      .on('end', () => {
        //console.log(InvestmentChartIcon)
        //d3.select(`#${chartId}`).node().append()
      });

    const tooltip = d3
      .select('body')
      .append('div')
      .attr('class', 'svg-tooltip')
      .attr('class', 'mt-5')
      .style('position', 'absolute')
      .style('visibility', 'hidden');

    const mouseEnterCallback = (d) => {
      d3.select(d.target).attr('stroke-width', '2').attr('stroke', d.target.style.fill);

      /* tooltip.style('visibility', 'visible').text(
        `${propTrans({
          prop: d.target.attributes['data-label'].value,
          scope: 'dashboard',
        })} ${rounder(d.target.attributes['data-value'].value)}`
      ); */
      tooltip.style('visibility', 'visible').text(
        `${d.target.attributes['data-label'].value} ${rounder(
          // d.target.attributes['data-value'].value
          data.done === 0 ? data.done : d.target.attributes['data-value'].value
        )}%`
      );
    };

    const mouseLeaveCallback = (d) => {
      d3.select(d.target).attr('stroke-width', '0');
      tooltip.style('visibility', 'hidden');
    };

    d3.select(`#${chartId}`)
      .selectAll('path')
      .on('mouseenter', mouseEnterCallback)
      .on('mousemove', (event) => {
        tooltip.style('top', `${event.pageY}px`).style('left', `${event.pageX + 10}px`);
      })
      .on('mouseleave', mouseLeaveCallback);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  return (
    <div>
      <svg id={chartId} preserveAspectRatio='xMinYMin meet' viewBox='0 0 200 200'></svg>
    </div>
  );
};

const ValutazioneHeader = ({
  activeStep,
  projectId,
  projectStatus,
  sectorCode,
  interventionClassCode,
  financingTypeCode,
  proponentCode,
  vanBenefits,
  translations,
  isUpdated,
  setIsUpdated,
  clientId,
  requestId,
  updatedAt,
  isLoading,
  setIsLoading,
  containsAzienda = false,
}) => {
  const [progress, setProgress] = useState({});
  const [dataGeneral, setDataGeneral] = useState({});
  const [dataESG, setDataESG] = useState({});
  const [dataSROI, setDataSROI] = useState({});
  const [donutProgress, setDonutProgress] = useState([]);
  const [showModalAlert, setShowModalAlert] = useState(false);
  const [showModalCheckVanBenefits, setShowModalCheckVanBenefits] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isSubmittingLoading, setIsSubmittingLoading] = useState(false);
  const fullDate = new Date(updatedAt);
  const date = `${fullDate.getDate()}-${fullDate.getMonth() + 1}-${fullDate.getFullYear()}`;
  const hours = `${fullDate.getHours()}:${fullDate.getMinutes()}`;
  console.log('progress', projectStatus);
  // console.log('donutProgress', donutProgress);
  // console.log('translations', translations);
  // console.log('interventionClassCode', interventionClassCode);

  const handleCloseModal = (setShowModal) => {
    setShowModal(false);
    //setShowModalAlert(false);
    setIsChecked(false);
  };
  console.log('donutProgress', donutProgress);
  //eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (projectId || isUpdated) {
      const percentageSROI = await getPercentageSROI(projectId);
      // console.log('percentageSROI', percentageSROI);
      //const percentageSROI = 0;

      const percentageESG = await getPercentageESG(projectId);
      //console.log('percentageESG', percentageESG);

      setProgress({
        general: {
          total: 100,
          done: 100,
          notdone: 0,
          threshold: 80,
        },
        esg: {
          total: 100,
          done: projectId === 1065 ? 100 : percentageESG,
          notdone: projectId === 1065 ? 0 : 100 - percentageESG,
          threshold: 40,
        },
        sroi: {
          total: 100,
          done: percentageSROI.percentage,
          notdone: 100 - percentageSROI.percentage,
          threshold: 100,
        },
      });

      setIsUpdated(false);
    } else {
      setProgress({
        general: {
          total: 100,
          done: 0,
          notdone: 100,
          threshold: 80,
        },
        esg: {
          total: 100,
          done: 0,
          notdone: 100,
          threshold: 40,
        },
        sroi: {
          total: 100,
          done: 0,
          notdone: 100,
          threshold: 100,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, isUpdated]);

  useEffect(() => {
    // console.log('progress', progress);

    if (Object.keys(progress).length > 0) {
      setDataGeneral(progress.general);
      setDataESG(progress.esg);
      // setDataSROI(progress.sroi)
      (proponentCode === 'ET' || interventionClassCode === 'NOINTPURCH') &&
      progress.general.done === 100
        ? setDataSROI(progress.sroi, (progress.sroi.done = 100), (progress.sroi.notdone = 0))
        : setDataSROI(progress.sroi);

      let tempDonutProgress = [];

      // if (sectorCode === 'EVENTI' || proponentCode === 'ET') {
      //   tempDonutProgress = [progress.general.done, progress.esg.done;
      // } else {
      //   tempDonutProgress = [progress.general.done, progress.esg.done, progress.sroi.done];
      // }
      tempDonutProgress = [
        progress.general.done,
        projectId === 1065 ? 100 : progress.esg.done,
        ...(containsAzienda ? [] : [progress.sroi.done]),
      ];
      setDonutProgress(tempDonutProgress);
    }
  }, [progress, proponentCode, sectorCode, isUpdated, interventionClassCode, containsAzienda]);

  const checkVanBenefits = () => {
    if (
      interventionClassCode !== 'NOINTPURCH' &&
      (vanBenefits <= 0 || !vanBenefits) &&
      !containsAzienda
    ) {
      setShowModalCheckVanBenefits(true);
    } else {
      setShowModalAlert(true);
    }
  };
  // const checkVanBenefits = () => {
  //   if (financingTypeCode !== 'TEMPNUOVO' && (vanBenefits <= 0 || !vanBenefits)) {
  //     setShowModalCheckVanBenefits(true);
  //   } else {
  //     setShowModalAlert(true);
  //   }
  // };

  const changeToSubmitted = async (e) => {
    e.preventDefault();
    setIsSubmittingLoading(true);
    InfoToast('Salvataggio...', 'Stiamo apportando le modifiche', false);
    try {
      await putChangeStatusOnSubmitted(projectId, 30000);
      setIsSubmittingLoading(false);
      if (localStorage.getItem('ics_role') === '2' || localStorage.getItem('ics_role') === '3') {
        SuccessToast('Operazione conclusa', 'Progetto inviato correttamente');
      }

      setTimeout(() => {
        if (localStorage.getItem('ics_role') === '1') {
          logout();
          //window.location.assign(`/thanks`);
          window.location = `/thanks?ndg=${clientId}&rapporto=${requestId}&date=${date}&hours=${hours}&id=${projectId}`;
        } else if (
          localStorage.getItem('ics_role') === '2' ||
          localStorage.getItem('ics_role') === '3'
        ) {
          window.location.assign('/progetti');
        }

        //window.location.reload();
      }, 1000);
    } catch (error) {
      setIsSubmittingLoading(false);
      DangerToast('Attenzione', 'Errore nella sottomissione del progetto');
    }
    //console.log('result', result);
  };

  const checkLabel = (
    <>
      <p className='check-text mb-4'>
        “{translations.filter((trans) => trans.code === 'ICS108').map((trans) => trans.description)}
        &nbsp;
        <a
          className='a-check-text'
          href={translations
            .filter((trans) => trans.code === 'ICS144')
            .map((trans) => trans.description)}
          target='_blank'
          rel='noreferrer'>
          {translations.filter((trans) => trans.code === 'ICS144').map((trans) => trans.text)}
        </a>
        <span>
          {translations
            .filter((trans) => trans.code === 'ICS110')
            .map((trans) => trans.description)}
        </span>
      </p>

      <p className='check-text'>
        {translations.filter((trans) => trans.code === 'ICS111').map((trans) => trans.description)}
        &nbsp;
      </p>
    </>
  );

  return (
    <div className='simple-box impact mt-0'>
      <div className='d-flex flex-wrap justify-content-center'>
        <div className='chartBox impactChart mx-4 my-3'>
          <a
            href={
              projectId && !containsAzienda
                ? `/valutazione/${projectId}`
                : projectId && containsAzienda
                ? `/valutazione/${projectId}/azienda`
                : '#'
            }>
            <h5>
              1.{' '}
              {translations.filter((trans) => trans.code === 'ICS001').map((trans) => trans.text)}
            </h5>
          </a>
          {Object.keys(dataGeneral).length > 0 && Object.keys(progress).length > 0 ? (
            <AnimatedDonutChart
              chartId='general'
              data={dataGeneral}
              current={activeStep === 'general'}
              progress={progress}
              style={{ marginTop: '1rem' }}
            />
          ) : null}

          {activeStep === 'general' && projectStatus !== 'SUBMITTED' && (
            <Button form='dati-generali-form' id='saveAll' type='submit' className='mt-3'>
              SALVA
            </Button>
          )}
        </div>

        <div className='chartBox impactChart mx-4 my-3'>
          <a
            href={
              projectId && !containsAzienda
                ? `/valutazione/${projectId}/esg`
                : projectId && containsAzienda
                ? `/valutazione/${projectId}/azienda/esg`
                : '#'
            }>
            <h5 style={{ whiteSpace: 'nowrap' }}>
              2.{' '}
              {translations.filter((trans) => trans.code === 'ICS029').map((trans) => trans.text)}
            </h5>
          </a>
          {Object.keys(dataGeneral).length > 0 ? (
            <AnimatedDonutChart
              chartId='esg'
              data={dataESG}
              current={activeStep === 'esg'}
              progress={progress}
              style={{ marginTop: '1rem' }}
            />
          ) : null}

          {activeStep === 'esg' && projectStatus !== 'SUBMITTED' && (
            <Button form='valutazione-esg-form' id='saveAll' type='submit' className='mt-3'>
              SALVA
            </Button>
          )}
        </div>

        {/* (sectorCode && sectorCode === 'EVENTI') ||
        (proponentCode && proponentCode === 'ET') ? null : */}
        {!containsAzienda && (
          <div
            className='chartBox impactChart mx-4 my-3'
            style={{
              pointerEvents:
                sectorCode === 'EVENTI' ||
                proponentCode === 'ET' ||
                proponentCode === 'LIGHTPRO' ||
                proponentCode === 'LIGHTNOPRO' ||
                interventionClassCode === 'NOINTPURCH'
                  ? 'none'
                  : '',
            }}>
            <a href={projectId ? '/valutazione/' + projectId + '/sroi' : '#'}>
              <h5>
                3.{' '}
                {translations.filter((trans) => trans.code === 'ICS017').map((trans) => trans.text)}
              </h5>
            </a>
            {Object.keys(dataGeneral).length > 0 ? (
              <AnimatedDonutChart
                chartId='sroi'
                data={dataSROI}
                current={activeStep === 'sroi'}
                progress={progress}
                style={{ marginTop: '1rem' }}
              />
            ) : null}

            {activeStep === 'sroi' && proponentCode !== 'AUD' && projectStatus !== 'SUBMITTED' && (
              <Button form='valutazione-sroi-form' id='saveAll' type='submit' className='mt-3'>
                SALVA
              </Button>
            )}
          </div>
        )}

        <div className='chartBox impactChart mx-4 my-3'>
          <h5>
            {/* {(sectorCode && sectorCode === 'EVENTI') || (proponentCode && proponentCode === 'ET')
              ? '3.'
              : '4.'}{' '} */}
            {containsAzienda ? '3. ' : '4. '}
            {translations.filter((trans) => trans.code === 'ICS030').map((trans) => trans.text)}
          </h5>

          {projectStatus !== 'SUBMITTED' ? (
            <div className='d-flex justify-content-center align-items-center flex-column'>
              <OverlayTrigger
                placement='top'
                delay={{ show: 200, hide: 400 }}
                overlay={
                  <Tooltip id='button-tooltip'>
                    Per inviare è necessario che tutte le sezioni siano completate al 100%
                  </Tooltip>
                }
                {...(donutProgress.every((value) => value === 100) ? { show: false } : null)}>
                <span className='d-inline-block' style={{ margin: '4rem auto 2rem auto' }}>
                  <Button
                    size='lg'
                    type='submit'
                    style={
                      !donutProgress.every((value) => value === 100)
                        ? { pointerEvents: 'none' }
                        : null
                    }
                    onClick={() => checkVanBenefits()}
                    disabled={
                      donutProgress.length > 0 || isLoading
                        ? !donutProgress.every((value) => value === 100) || isLoading
                        : true
                    }>
                    INVIA
                  </Button>
                </span>
              </OverlayTrigger>
              {/* {donutProgress.length > 0 && donutProgress.every((value) => value === 100) && (
                <TutorialsButtonWrapper
                  isThanks
                  url={'https://www.youtube.com/embed/ktF2799YdK0?si=GbWTlGVcT9-Fidor'}
                  videoTitle={'Tutorial Sottomissione Progetto'}
                />
              )} */}
            </div>
          ) : (
            <>
              <Row className='flex-column align-items-center' style={{ marginTop: '4.5rem' }}>
                <Button size='lg' id='submitted-button' variant='outline-dark' disabled>
                  INVIATO
                </Button>

                <Row>
                  {' '}
                  <Button
                    variant='link'
                    className='bg-transparent border-white mt-2'
                    href={`/thanks?ndg=${clientId}&rapporto=${requestId}&date=${date}&hours=${hours}&id=${projectId}`}
                    target='_blank'>
                    <Print
                      title={translations
                        .filter((trans) => trans.code === 'ICS097')
                        .map((trans) => trans.text)}
                      className='m-0 second-color'
                    />
                  </Button>
                  {proponentCode === 'ET' &&
                    projectStatus === 'SUBMITTED' &&
                    localStorage.getItem('ics_role') === '1' && (
                      <Button
                        variant='link'
                        className='bg-transparent border-white mt-2 ml-3'
                        href={`/projectresults/${btoa(projectId)}/${btoa(requestId)}`}
                        target='_blank'>
                        <ReportPrint
                          style={{ fill: '#08a2aa' }}
                          title={translations
                            .filter((trans) => trans.code === 'ICS134')
                            .map((trans) => trans.text)}
                          className='m-0 second-color'
                        />
                      </Button>
                    )}
                </Row>
              </Row>
            </>
          )}
        </div>
      </div>

      <Modal
        size='lg'
        show={showModalAlert}
        onHide={() => handleCloseModal(setShowModalAlert)}
        centered>
        {isSubmittingLoading && <LoadingSpinner />}
        <Modal.Header closeButton>
          <Modal.Title>Attenzione</Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-5'>
          <p className='mb-4'>
            Effettuando la sottomissione non sarai più in grado di modificare i dati generali, le
            valutazioni e la compilazione del sondaggio associato.
          </p>
          <div>
            <Form.Group className='mb-3' controlId='checkPreSubmit'>
              <Form.Check
                type='checkbox'
                label={checkLabel}
                id='checkPreSubmit'
                onChange={() => setIsChecked(!isChecked)}
              />
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='success' onClick={() => handleCloseModal(setShowModalAlert)}>
            Annulla
          </Button>
          <Button variant='primary' disabled={!isChecked} onClick={(e) => changeToSubmitted(e)}>
            Conferma
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size='lg'
        show={showModalCheckVanBenefits}
        onHide={() => handleCloseModal(setShowModalCheckVanBenefits)}
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Attenzione</Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-5'>
          <p className='my-3'>
            {translations.filter((trans) => trans.code === 'ICS117').map((trans) => trans.text)}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='success' onClick={() => handleCloseModal(setShowModalCheckVanBenefits)}>
            Chiudi
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ValutazioneHeader;
