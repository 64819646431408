import React, { useState /* useEffect */ } from 'react';
import { Container, Row, Col, Form, Button /* Tab, Nav, InputGroup */ } from 'react-bootstrap';
import { Externalyticslogo, PoweredbyOE } from 'loghi';
import { /* getTranslations, */ checkLogin } from '@lib/api';
import Functionality from '../pages/funzionalita';
import { DangerToast } from '@components/GlobalToastList';
import Select from 'react-select';
/* import { Eye, EyeSlash } from '@icons'; */

const Home = (/* {referrer} */) => {
  //const [translations, setTranslations] = useState([]);
  const [clientID, setClientID] = useState('');
  const [requestID, setRequestID] = useState('');
  const [selectedType, setSelectedType] = useState('');
  /*   const [email, setEmail] = useState('');
  const [password, setPassword] = useState(''); */
  /*const [loginType,  setLoginType ] = useState(1);*/
  //const [showPass, setShowPass] = useState(false);
  const [areCredentialsCorrect, setAreCredentialsCorrect] = useState(true);
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line
  let loginType = 1;
  //const [selectedTab, setSelectedTab] = useState('first');

  /* useEffect(() => {
    getTranslations().then((retrievedTranslation) => {
      setTranslations(retrievedTranslation);
    });
  }, []); */

  const handleLogin = async (event) => {
    event.preventDefault();
    //console.log('loginType', loginType);
    let type = selectedType;
    let field1 = clientID;
    let field2 = requestID;

    if (field1.match(emailRegex)) {
      loginType = 2;
    }

    if (type === '') {
      DangerToast('Attenzione', 'Selezionare il tipo di richiesta');

      return;
    }
    if (field1 === '' || field2 === '') {
      DangerToast('Attenzione', 'I campi non possono essere vuoti.');

      return;
    }

    //console.log('field1', field1);
    //console.log('field2', field2);

    await checkLogin(field1, field2, loginType).then((status) => {
      //console.log('code', status)
      if (status === 200) {
        setAreCredentialsCorrect(true);

        let project_id = localStorage.getItem('ics_project_id');
        //console.log('localStorage', localStorage)

        if (Number(localStorage.getItem('ics_role')) === 1) {
          if (project_id === 'null') {
            if (selectedType.value !== 'azienda') {
              window.location.assign('valutazione/');
            } else {
              window.location.assign('valutazione/azienda');
            }
          } else {
            if (selectedType.value !== 'azienda') {
              window.location.assign(`valutazione/${project_id}`);
            } else {
              window.location.assign(`valutazione/${project_id}/azienda`);
            }
          }
        } else {
          setAreCredentialsCorrect(false);
          return;
        }
      } else {
        console.log('error code:', status);
        if (status === 401) {
          setAreCredentialsCorrect(false);
        }
      }
    });
  };

  /* const clickHandler = () => setShowPass(!showPass); */

  return (
    <>
      <div className='header-site'>
        <Container>
          <Row className='justify-content-between'>
            <Col lg={5} className='text-login'>
              <a href='/'>
                <Externalyticslogo className='mainlogo' />
              </a>
              <div>
                {/* <small>Powered by</small>
                <Externalyticslogo className="externalyticslogo" /> */}
                <PoweredbyOE style={{ minWidth: '9rem', minHeight: '4rem' }} />
              </div>
            </Col>
            <Col lg={5} className='claim' style={{ textAlign: 'right' }}>
              <p className='infoLogin'>
                Strumento per la misurazione degli impatti economici e sociali attraverso
              </p>
              <p className='claimLogin'>
                Soluzioni ESG
                <br />
                (Environmental, Social, Governance)
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='login-container'>
        <Container>
          <Row>
            <Col lg={3} className='text-login'></Col>
            <Col lg={5} className='simple-box form-login'>
              {/* <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row style={{ flexDirection: 'column', alignItems: 'center' }}>
                  <Col>
                    <Nav variant="pills" style={{ justifyContent: 'center' }} onSelect={(selectedKey) => setSelectedTab(selectedKey)}>
                      <Nav.Item  onClick={() => setLoginType(1)}>
                        <Nav.Link eventKey="first" className="tab-name-login">
                          Compila richiesta
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item  onClick={() => setLoginType(2)}>
                        <Nav.Link eventKey="second" className="tab-name-login">
                          Accesso
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col style={{ marginTop: '2rem' }}>
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <Form id="standard-access-form">
                          <Form.Group className="mb-3">
                            <Form.Control type="text" name="clientID" value={clientID} placeholder="ID cliente" onChange={({target: { value }}) => setClientID(value)} />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control type="text" name="requestID" value={requestID} placeholder="ID richiesta" onChange={({target: { value }}) => setRequestID(value)} />
                          </Form.Group>                          
                        </Form>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Form id="ics-access-form">
                          <Form.Group className="mb-3">                            
                            <Form.Control type="email" name="email" value={email} placeholder="Email" onChange={({target: { value }}) => setEmail(value)} />
                          </Form.Group>
                          <Form.Group className="mb-3">  
                            <InputGroup>                         
                              <Form.Control 
                                type={showPass ? "text" : "password"}
                                rows={3} 
                                name="password" 
                                value={password} 
                                placeholder="Password" 
                                onChange={({target: { value }}) => setPassword(value)} 
                              />
                              <InputGroup.Text className="addon-for-number">
                                {showPass ? <Eye className="eye" onClick={clickHandler} /> : <EyeSlash className="eye" onClick={clickHandler} />}
                              </InputGroup.Text>
                            </InputGroup> 

                            {areCredentialsCorrect ? null : <span className="errorMessage"><h5>Credenziali errate.</h5></span>}
                          </Form.Group>                          
                        </Form>
                      </Tab.Pane>
                    </Tab.Content>
                    <Button
                      variant="info"
                      form={selectedTab === 'first' ? 'standard-access-form' : 'ics-access-form'}
                      style={{
                        backgroundColor: '#1C2743',
                        borderColor: '#1C2743',
                      }}
                      type="submit"
                      onClick={handleLogin}
                    >
                      Login
                    </Button>
                  </Col>
                </Row>
              </Tab.Container> */}

              <Row id='left-tabs-example' style={{ flexDirection: 'column', alignItems: 'center' }}>
                <Col>
                  <h5 className='mb-4'>Compila richiesta</h5>
                  <div className='mb-3'>
                    <Select
                      name='interventionClassCode'
                      placeholder='Seleziona il tipo di richiesta'
                      value={selectedType}
                      options={[
                        { value: 'azienda', label: 'Azienda' },
                        { value: 'progetto', label: 'Progetto' },
                      ]}
                      onChange={(e) => setSelectedType(e)}
                      styles={{
                        placeholder: (provided) => ({
                          ...provided,
                          textAlign: 'center',
                          width: '100%',
                          display: 'block',
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          textAlign: 'center',
                          width: '100%',
                        }),
                        input: (provided) => ({
                          ...provided,
                          textAlign: 'center',
                          width: '100%',
                        }),
                        control: (provided) => ({
                          ...provided,
                          textAlign: 'center',
                        }),
                      }}
                      // isDisabled={project.status === 'SUBMITTED' ? true : false}
                    />
                  </div>
                  <Form id='standard-access-form'>
                    <Form.Group className='mb-3'>
                      <Form.Control
                        type='text'
                        name='clientID'
                        value={clientID}
                        //placeholder={translations.filter(trans => trans.code === 'ICS002').map(trans => trans.text)}
                        placeholder='Email'
                        onChange={({ target: { value } }) => setClientID(value)}
                      />
                    </Form.Group>
                    <Form.Group className='mb-3'>
                      <Form.Control
                        type='text'
                        name='requestID'
                        value={requestID}
                        //placeholder={translations.filter(trans => trans.code === 'ICS003').map(trans => trans.text)}
                        placeholder='Identificativo'
                        onChange={({ target: { value } }) => setRequestID(value)}
                      />
                      {areCredentialsCorrect ? null : (
                        <div className='my-4'>
                          <span>
                            <h5 className='errorMessage'>Credenziali errate.</h5>
                            Sei un utente registrato?{' '}
                            <a href='/admin' id='link-access'>
                              Accedi qui.
                            </a>
                          </span>
                        </div>
                      )}
                    </Form.Group>
                  </Form>
                  <Button
                    variant='info'
                    form='standard-access-form'
                    style={{ backgroundColor: '#1C2743', borderColor: '#1C2743' }}
                    type='submit'
                    onClick={handleLogin}>
                    Login
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <Functionality />
    </>
  );
};

export default Home;
