import React, { useState, useEffect } from 'react';
import { Col, Form, Button, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select from 'react-select';
import { TooltipInfoIcon, Eye, EyeSlash } from '@icons';
import { getUsers, editUserPassword } from '@lib/api';
import { SuccessToast, DangerToast } from '@components/GlobalToastList';

const initialState = {
  oldEmail: '',
  newPassword: '',
  id: '',
};

const UserModification = () => {
  const [optionsUsers, setOptionsUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);

  const [users, setUsers] = useState([]);
  const [userToEdit, setUserToEdit] = useState(initialState);
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/; // eslint-disable-line
  //console.log('users', users);
  //console.log('userToEdit', userToEdit);

  const clickHandler = () => setShowPass(!showPass);

  useEffect(() => {
    getUsers(2).then((retrievedUsers) => { //solo utenti di ruolo 2
      setUsers(retrievedUsers);

      setOptionsUsers(
        retrievedUsers.map((user) => ({
          value: user.email,
          label: user.email,
        }))
      );
    });

    setUserToEdit(() => ({
      ...userToEdit,
      //eslint-disable-next-line no-useless-computed-key
      ['newPassword']: '',
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedUser(optionsUsers.filter((optionsUser) => optionsUser.value === userToEdit.oldEmail)
        .map((filteredUser) => ({
          value: filteredUser.value,
          label: filteredUser.label,
        }))
    );
  }, [optionsUsers, userToEdit]);

  const onChange = (e, fieldname, setSelect) => {
    //console.log('e', e);
    
    if (setSelect) { //email
      setSelect(e);

      setUserToEdit(() => ({
        ...userToEdit,
        [fieldname]: e.value,
        //eslint-disable-next-line no-useless-computed-key
        ['id']: Number(users.filter(user => user.email === e.value).map(filteredUser => filteredUser.id).join()),
      }));

    } else { //passw
      setUserToEdit(() => ({
        ...userToEdit,
        [fieldname]: e.target.value,
      }));
    }
  };

  const onBlur = (value, regex, setState) => {
    if (value && value.match(regex)) {
      setState(false);
    } else {
      setState(true);
    }
  };

  const submit = async (e) => {
    e?.preventDefault();

    if (!userToEdit.oldEmail || !userToEdit.newPassword) {
      DangerToast(
        'Attenzione',
        'Tutti i campi sono obbligatori.'
      );
      return;
    }

    if (!userToEdit.newPassword.match(passwordRegex)) {
      DangerToast(
        'Attenzione',
        'Rispetta i criteri della password.'
      );
      return;
    }

    const result = await editUserPassword(userToEdit);

    //console.log('result:', result);

    if (result.id) {
      SuccessToast(
        'Operazione conclusa',
        `Utente modificato correttamente.`
      );

     setTimeout(() => {
        window.location.reload();
      }, 1500);
    } else {
      DangerToast(
        'Attenzione',
        `Errore nella modifica dell'utente.`
      );
    }
  };

  return (
    <div id="user-modification">
      <Col sm={8}>
        <Form onSubmit={e => submit(e)} id="user-modification-form" autoComplete="off">
          <div className="simple-box" style={{ boxShadow: 'none', margin: 0, paddingTop: '0.4rem'}}>
            <h5 className="title-simple-box margin-title-box">
              Modifica un utente
            </h5>

            <Form.Group as={Col} controlId="oldEmail">
              <Form.Label>Seleziona utente</Form.Label>
              <Select
                name="oldEmail"
                placeholder="Seleziona..."
                value={selectedUser}
                options={optionsUsers}
                onChange={(e) => onChange(e, 'oldEmail', setSelectedUser)}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="newPassword">
              <Form.Label>
                Password
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 200, hide: 400 }}
                  overlay={<Tooltip id="button-tooltip">La password deve contenere: 
                    <ul style={{textAlign: 'left', paddingLeft: '20px', margin: 'auto'}}>
                      <li>una lettera maiuscola,</li>
                      <li>un numero,</li>
                      <li>un carattere speciale,</li>
                      <li>una lunghezza minima di 8 caratteri.</li>
                    </ul>
                  </Tooltip>}
                >
                  <TooltipInfoIcon className="tooltip-ico" style={{width: '1rem'}} />
                </OverlayTrigger>
              </Form.Label>
              <InputGroup>          
                <Form.Control
                  type={showPass ? "text" : "password"}
                  name="newPassword"
                  size="lg"
                  defaultValue={userToEdit.newPassword}
                  onChange={(e) => onChange(e, 'newPassword')}
                  onBlur={(e) => onBlur(e.target.value, passwordRegex, setPasswordInvalid)}
                  isInvalid={passwordInvalid}
                  autoComplete="new-password"
                />
              <InputGroup.Text className="addon-for-number">
                  {showPass ? <Eye className="eye" onClick={clickHandler} /> : <EyeSlash className="eye" onClick={clickHandler} />}
                </InputGroup.Text>
              </InputGroup> 
            </Form.Group>
          </div>
            
          <Button
            type="submit" 
            form="user-modification-form" 
            variant="primary" 
            className="user-button create-edit-user-button"
          >
            MODIFICA
          </Button>
        </Form>
      </Col>
    </div>
  );
};

export default UserModification;