import React from 'react';
import { ProjectsTab } from '@components/projects';

const Progetti = () => {
  return (
    <div id="progetti" className="megaContent">
      <div className="container-fluid">
        <ProjectsTab />
      </div>
    </div>
  );
};

export default Progetti;
