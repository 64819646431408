import { Spinner } from 'react-bootstrap';

function LoadingSpinner() {
  return (
    <>
      <div className='loading-spinner-overlay state-loading-spinner'></div>
      <div className='d-flex justify-content-center align-items-center loading-spinner-container state-loading-spinner'>
        <Spinner variant='dark' animation='border' role='status'></Spinner>
        <span className='visually-hidden ml-4 loading-spinner-text'>
          Stiamo apportando le modifiche richieste.
        </span>
      </div>
    </>
  );
}

export default LoadingSpinner;
