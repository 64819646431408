import React, { useState, useEffect } from 'react';
import { Col, Form, Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { getUsers, deleteUser } from '@lib/api';
import { SuccessToast, DangerToast } from '@components/GlobalToastList';

const initialState = {
  userEmail: '',
};

const UserDeletion = () => {
  const [optionsUsers, setOptionsUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);

  const [users, setUsers] = useState([]);
  const [userToDelete, setUserToEdit] = useState(initialState);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  //console.log('userToDelete', userToDelete);

  useEffect(() => {
    getUsers(2).then((retrievedUsers) => { //solo utenti di ruolo 2
      setUsers(retrievedUsers);

      setOptionsUsers(
        retrievedUsers.sort((a, b) => a.email.localeCompare(b.email))
        .map((user) => ({
          value: user.email,
          label: user.email,
        }))
      );
    });
  }, []);

  useEffect(() => {
    setSelectedUser(optionsUsers.filter((optionsUser) => optionsUser.value === userToDelete.userEmail)
        .map((filteredUser) => ({
          value: filteredUser.value,
          label: filteredUser.label,
        }))
    );
  }, [optionsUsers, userToDelete]);

  useEffect(() => {
    if (isConfirmed) { submit() }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmed]);

  const onChange = (e, fieldname, setSelect) => { 
    if (setSelect) { //email
      setSelect(e);

      setUserToEdit(() => ({
        ...userToDelete,
        [fieldname]: e.value,
        //eslint-disable-next-line no-useless-computed-key
        ['id']: Number(users.filter(user => user.email === e.value).map(filteredUser => filteredUser.id).join()),
      }));

    } else { //passw
      setUserToEdit(() => ({
        ...userToDelete,
        [fieldname]: e.target.value,
      }));
    }
  };

  const checkConfirmation = async (boolean) => {
    setIsConfirmed(boolean);

    boolean ? submit() : setShowModalDelete(false);
  };

  const submit = async (e) => {
    e?.preventDefault();

    if (!userToDelete.userEmail) {
      DangerToast(
        'Attenzione',
        'Seleziona un utente.'
      );
      return;
    }

    let result;

    if (!isConfirmed) {
        setShowModalDelete(true);

        return;
    } else {
        result = await deleteUser(userToDelete.id);
    }

    //console.log('result:', result);

    if (result) {
      SuccessToast(
        'Operazione conclusa',
        `Utente eliminato correttamente.`
      );

     setTimeout(() => {
        window.location.reload();
      }, 1500);
    } else {
      DangerToast(
        'Attenzione',
        `Errore nell'eliminazione dell'utente.`
      );
    }
  };

  return (
    <div id="user-deletion">
        <Col sm={8}>
            <Form onSubmit={e => submit(e)} id="user-deletion-form" autoComplete="off">
                <div className="simple-box" style={{ boxShadow: 'none', margin: 0, paddingTop: '0.4rem'}}>
                    <h5 className="title-simple-box margin-title-box">
                        Elimina un utente
                    </h5>

                    <Form.Group as={Col} controlId="userEmail">
                        <Form.Label>Seleziona utente</Form.Label>
                        <Select
                        name="userEmail"
                        placeholder="Seleziona..."
                        value={selectedUser}
                        options={optionsUsers}
                        onChange={(e) => onChange(e, 'userEmail', setSelectedUser)}
                        />
                    </Form.Group>
                </div>
            
                <Button
                    type="submit" 
                    form="user-deletion-form" 
                    variant="danger" 
                    className="user-button red delete-user-button"
                >
                    ELIMINA
                </Button>
            </Form>
        </Col>

        <Modal show={showModalDelete} onHide={() => setShowModalDelete(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title>Attenzione</Modal.Title>
            </Modal.Header>
            <Modal.Body>Sei sicuro di voler eliminare l'utente?</Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={() => checkConfirmation(false)}>
                    Annulla
                </Button>
                <Button variant="primary" onClick={() => checkConfirmation(true)}>
                    Elimina
                </Button>
            </Modal.Footer>
        </Modal>
    </div>
  );
};

export default UserDeletion;