import React, { Component } from 'react';

class Ico91 extends Component {
  render() {
    let className = 'extsvg ';
    if (this.props.className !== undefined) {
      className += this.props.className;
    }

    return (
      <svg
        id="ico-91"
        viewBox="0 0 117.62 136"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        height={this.props.height}
        width={this.props.width}
      >
        <path
          d="M58.8103 0L117.621 34V102L58.8103 136L0 102V34L58.8103 0Z"
          className="esagono"
        />
        <g transform="translate(25.3,41.5)">
          <path
            d="M58.3727 0L46.1317 16.6361L43.3644 20.38L27.7051 41.639C27.3795 42.0622 27.119 42.5831 26.8912 43.0714C25.5238 46.4247 27.1842 50.2663 30.5374 51.6011C31.3513 51.9266 32.1652 52.0894 32.9791 52.0894C35.5836 52.0894 38.0253 50.5267 39.0671 47.9874L50.3965 19.8591L51.5685 16.9291L58.3727 0Z"
            className="ico"
          />
          <path
            d="M36.2021 18.589L40.76 12.4034C38.4159 11.8825 36.0068 11.6221 33.5326 11.6221C15.0408 11.6221 0 26.6629 0 45.1221H6.83673C6.83673 30.4068 18.8173 18.4588 33.5 18.4588C34.4116 18.4588 35.3231 18.5239 36.2021 18.589Z"
            className="ico"
          />
          <path
            d="M57.1356 21.4219L54.3032 28.4865C57.982 33.0443 60.1633 38.8393 60.1633 45.1551H67C67 35.8767 63.2235 27.4773 57.1356 21.4219Z"
            className="ico"
          />
        </g>
      </svg>
    );
  }
}
export default Ico91;
