import React, { useState } from 'react';
import { Toast } from 'react-bootstrap';
import { IcoWarning, IcoDanger, IcoSuccess, IcoInfo } from '@icons';

let addToastHandler;

export const WarningToast = (title, message, autoHide = true) => {
  return typeof window === 'undefined'
    ? null
    : addToast('khaki', <IcoWarning />, title, message, autoHide);
};

export const DangerToast = (title, message, autoHide = true) => {
  return typeof window === 'undefined'
    ? null
    : addToast('indianred', <IcoDanger />, title, message, autoHide);
};

export const SuccessToast = (title, message, autoHide = true) => {
  return typeof window === 'undefined'
    ? null
    : addToast('darkseagreen', <IcoSuccess />, title, message, autoHide);
};

export const InfoToast = (title, message, autoHide = true) => {
  return typeof window === 'undefined'
    ? null
    : addToast('cornflowerblue', <IcoInfo />, title, message, autoHide);
};

const addToast = (color, icon, title, message, autoHide) => {
  const toastOptions = { color, icon, title, message, autoHide };

  return typeof window === 'undefined' ? null : addToastHandler(toastOptions);
};

export const GlobalToastList = () => {
  const [toast, setToast] = useState({});
  const [showToast, setShowToast] = useState(false);

  addToastHandler = (toastOptions) => {
    return typeof window === 'undefined' ? null : (setToast(toastOptions), setShowToast(true));
  };

  return (
    <Toast
      className='global-toast'
      onClose={() => setShowToast(false)}
      show={showToast}
      delay={5000}
      autohide={toast.autoHide}>
      <Toast.Header className='d-flex bd-highlight'>
        <div className='p-2 bd-highlight' style={{ fill: toast.color }}>
          {toast.icon}
        </div>
        <strong className='p-2 bd-highlight mr-auto' style={{ color: toast.color }}>
          {toast.title}
        </strong>
      </Toast.Header>
      <Toast.Body className='p-3'>{toast.message}</Toast.Body>
    </Toast>
  );
};
