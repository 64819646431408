import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { OpeneconomicsLogo } from 'loghi';
import moment from 'moment';

function Footer() {
  return (
    <footer id='site-footer'>
      <Container>
        <Row>
          <Col className='text-center'>
            {window.location.pathname !== '/login' && (
              <>
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <a
                  href='https://www.openeconomics.eu/'
                  target='_blank'
                  alt='OpenEconomics'
                  title='OpenEconomics'>
                  <small>Powered by</small>
                  <OpeneconomicsLogo
                    className='openeconomicslogo'
                    style={{ width: '15%', marginLeft: '0.7rem' }}
                  />
                </a>
              </>
            )}
            <Row className='credits'>
              <Col xs={12} md='auto' className='credit-logo'>
                <img src='/img/global-company.png' alt='global compact' className='grey' />
              </Col>
              <Col xs={12} md='auto' className='credit-logo'>
                <img src='/img/ees.png' alt='ees' className='grey' />
              </Col>
              <Col xs={12} md='auto' className='credit-logo'>
                <img src='/img/sites.png' alt='sites' className='grey' />
              </Col>
              <Col xs={12} md='auto' className='credit-logo'>
                <img src='/img/rina.jpg' alt='ria' className='grey' />
              </Col>
            </Row>
            <div className='copyright'>
              © {moment().format('Y')} Copyright OpenEconomics | Vat 12504821005
            </div>
            <Row className='footer-menu'>
              <Col>
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <span className='footer-menu-item no-to-head'>
                  <a href='https://www.openeconomics.eu/contatti' target='_blank' rel='noreferrer'>
                    Contacts
                  </a>
                </span>
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <span className='footer-menu-item no-to-head'>
                  <a href='https://www.openeconomics.eu/legal' target='_blank' rel='noreferrer'>
                    Legal
                  </a>
                </span>
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <span className='footer-menu-item no-to-head'>
                  <a href='https://www.openeconomics.eu/privacy' target='_blank' rel='noreferrer'>
                    Privacy
                  </a>
                </span>
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <span className='footer-menu-item no-to-head'>
                  <a href='https://www.openeconomics.eu/cookie' target='_blank' rel='noreferrer'>
                    Cookie policy
                  </a>
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
