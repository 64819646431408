import React, { Component } from 'react';

class Ico47 extends Component {
  render() {
    let className = 'extsvg ';
    if (this.props.className !== undefined) {
      className += this.props.className;
    }

    return (
      <svg
        id="ico-44"
        viewBox="0 0 117.62 136"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        height={this.props.height}
        width={this.props.width}
      >
        <path
          d="M58.8103 0L117.621 34V102L58.8103 136L0 102V34L58.8103 0Z"
          className="esagono"
        />
        <g transform="translate(27.8,48)">
          <path
            d="M61 3.33333C61.0316 3.10107 61.0316 2.8656 61 2.63333C60.971 2.43763 60.9149 2.24691 60.8333 2.06667C60.7453 1.90376 60.645 1.74776 60.5333 1.6C60.4065 1.38918 60.2493 1.19823 60.0667 1.03333L59.6667 0.799999C59.4743 0.656522 59.2606 0.544049 59.0333 0.466666H58.3667C58.1635 0.269983 57.9264 0.111858 57.6667 0H41C40.116 0 39.2681 0.35119 38.643 0.976311C38.0179 1.60143 37.6667 2.44928 37.6667 3.33333C37.6667 4.21739 38.0179 5.06523 38.643 5.69035C39.2681 6.31548 40.116 6.66667 41 6.66667H50.4333L37.1 22.3667L22.7 13.8C22.018 13.3943 21.2135 13.2453 20.4314 13.3797C19.6493 13.5142 18.9408 13.9232 18.4333 14.5333L1.76668 34.5333C1.486 34.8702 1.27454 35.259 1.14439 35.6777C1.01424 36.0964 0.967966 36.5366 1.00822 36.9732C1.04847 37.4098 1.17446 37.8342 1.37897 38.222C1.58347 38.6098 1.86248 38.9535 2.20001 39.2333C2.79971 39.7303 3.5545 40.0015 4.33334 40C4.82304 40.0008 5.30691 39.8937 5.75052 39.6863C6.19413 39.4789 6.5866 39.1763 6.90001 38.8L21.7333 21L35.9667 29.5333C36.6418 29.9338 37.4365 30.0831 38.2109 29.955C38.9854 29.827 39.6897 29.4298 40.2 28.8333L54.3333 12.3333V20C54.3333 20.8841 54.6845 21.7319 55.3097 22.357C55.9348 22.9821 56.7826 23.3333 57.6667 23.3333C58.5507 23.3333 59.3986 22.9821 60.0237 22.357C60.6488 21.7319 61 20.8841 61 20V3.33333Z"
            className="ico"
          />
        </g>
      </svg>
    );
  }
}
export default Ico47;
