import React from 'react';
import { Footer, Header, Menu, Sidebarmenu } from './components';
import { useLocation } from 'react-router-dom';

const LayoutSideMenu = ({ children }) => {
  const location = useLocation();
  const isWhite =
    location.pathname.indexOf('survey') === 1 ||
    location.pathname.indexOf('gestione-utenti') === 1 ||
    location.pathname.indexOf('thanks') === 1
      ? 'white'
      : '#e5e5e5';

  return (
    <div className='App'>
      <Sidebarmenu />
      <Header />
      <div className='main-container'>
        <div className='desktop-menu'>
          <Menu />
        </div>
        <div className='content-page' style={{ backgroundColor: isWhite }}>
          {children}
        </div>
      </div>
      <Footer />
    </div>
  );
};

const LayoutBase = ({ children }) => {
  const location = useLocation();
  const isWhite =
    location.pathname.indexOf('survey') === 1 ||
    location.pathname.indexOf('gestione-utenti') === 1 ||
    location.pathname.indexOf('thanks') === 1 ||
    location.pathname.indexOf('projectresults') === 1
      ? 'white'
      : '#e5e5e5';

  return (
    <div className='App'>
      <Header />
      <div className='main-container'>
        <div className='content-page' style={{ backgroundColor: isWhite }}>
          {children}
        </div>
      </div>
      <Footer />
    </div>
  );
};

const LayoutSimple = ({ children }) => (
  <div className='App'>
    {children}
    <Footer />
  </div>
);

export { LayoutBase, LayoutSimple, LayoutSideMenu };
