import React, { useState, useEffect, useRef } from 'react';
import { Row, Form, Button } from 'react-bootstrap';
// import { Row, Form, Button, Accordion, Card } from 'react-bootstrap';
import { getAUDProjectsByProject, postAUDProject, deleteAUDProject } from '@lib/api';
import { IcoPlus } from '@icons';
// import { IcoPlus, ChevronRight } from '@icons';
import AUDProject from './AUDProject';

const ValutazioneSROIAUD = ({
  currentProject,
  translations,
  isUpdated,
  setIsUpdated,
  isLoading,
  setIsLoading,
}) => {
  const [project, setProject] = useState({});
  const [AUDProjects, setAUDProjects] = useState([]);
  console.log('AUDPROJECTSSSS', AUDProjects);
  const instructionsRef = useRef(null);
  // let defaultAUDPRoject = {
  //   name: 'Nuovo Progetto Audiovisivo',
  //   projectType: 'LONG',
  //   productionYearStart: 2023,
  //   productionDurationWeeks: 8,
  //   minsOperaDuration: 85,
  //   onaircinemaYear: 2024,
  //   onairtelevisionYear: 2025,
  //   onairplatformYear: 2025,
  //   otherCosts: 30000,
  //   productionGenre: 'COM',
  //   distributionContractCinema: true,
  //   contractCinemas: 200,
  //   distributionContractTelevision: true,
  //   distributionContractPlatforms: true,
  //   estimatedCinemaUsers: 932400,
  //   aboveTheLine: 12000000,
  //   belowTheLine: 3000000,
  // };

  let defaultAUDPRoject = {
    name: 'Nuovo Progetto Audiovisivo',
    projectType: 'LONG',
    productionYearStart: 0,
    productionDurationWeeks: 0,
    minsOperaDuration: 0,
    onaircinemaYear: 0,
    onairtelevisionYear: 0,
    onairplatformYear: 0,
    otherCosts: 0,
    productionGenre: 'COM',
    distributionContractCinema: true,
    contractCinemas: 0,
    distributionContractTelevision: true,
    distributionContractPlatforms: true,
    estimatedCinemaUsers: 0,
    aboveTheLine: 0,
    belowTheLine: 0,
  };

  const scrollToInstructions = () => {
    // instructionsRef.current.scrollIntoView({ behavior: 'smooth' });
    const element = instructionsRef.current;
    const offsetTop = element.offsetTop;
    const elementHeight = element.offsetHeight;
    const windowHeight = window.innerHeight;
    const scrollPosition = offsetTop + elementHeight / 2 - windowHeight / 2;

    window.scrollTo({
      top: scrollPosition,
      behavior: 'smooth',
    });
  };

  /**FUNCTIONS */
  const submit = (e) => {
    console.log('submit', e);
  };

  const handleAddAudProject = () => {
    setIsLoading(true);
    postAUDProject(project.id, defaultAUDPRoject).then((newAudProject) => {
      console.log(defaultAUDPRoject); // Qui mi restituisce quello modificato da me
      console.log('newAudProject', newAudProject); // Qui invece con i vecchi dati

      if (newAudProject.code === 200) {
        let AUDProjectArr = [];

        AUDProjects.forEach((a) => {
          AUDProjectArr.push(a);
        });

        AUDProjectArr.push(newAudProject.response);
        setAUDProjects(AUDProjectArr);
        setIsLoading(false);
      }
    });
  };

  const handleChangesInAudProject = (AUDproject) => {
    let AUDProjectArr = [];
    AUDProjects.forEach((a) => {
      if (a.id === AUDproject.id) {
        AUDProjectArr.push(AUDproject);
      } else {
        AUDProjectArr.push(a);
      }
    });

    setAUDProjects(AUDProjectArr);
    setIsUpdated(true);
  };

  const handleDeleteInAudProject = (AUDproject) => {
    setIsLoading(true);
    deleteAUDProject(AUDproject.id).then((success) => {
      if (success) {
        getAUDProjectsByProject(currentProject.id).then((resp) => {
          if (resp.code === 200) {
            setAUDProjects(resp.response);
            setIsUpdated(true);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        });
      } else {
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    setProject(() => ({
      ...currentProject,
    }));

    getAUDProjectsByProject(currentProject.id).then((resp) => {
      if (resp.code === 200) {
        setAUDProjects(resp.response);
      }
    });
  }, [currentProject]);

  const instructionSection = (
    <div ref={instructionsRef} className='simple-box my-5'>
      <Row className='aud-instruction-box m-0 d-flex justify-content-center align-items-center'>
        <span className='simple-sroi-span pl-4'>
          <span className='asterisk-nb'>*</span>Nella compilazione dei costi di produzione (sopra la
          linea, sotto la linea e producer fee), in caso di co-produzioni internazionali inserire il
          valore dell’intero costo di produzione (nazionale e internazionale) e non solo la parte
          nazionale di costi
        </span>
      </Row>
    </div>
  );

  // const instructionSection = (
  //   <div>
  //     <div className='instructionSection'>
  //       <p>
  //         Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer dolor est, interdum at
  //         imperdiet a, volutpat vitae lacus. In sed mollis lectus. Mauris sit amet fringilla sem.
  //         Sed id erat consequat, lobortis nisl mattis, interdum ante. Phasellus nec varius arcu.
  //         Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nec odio augue. Donec semper
  //         vestibulum quam, sit amet tempor erat blandit a. Proin non erat posuere diam mattis
  //         cursus. Nullam condimentum pretium felis id rutrum. Proin non dolor posuere, rutrum felis
  //         non, suscipit ex. Nunc laoreet consectetur lacus non posuere.
  //       </p>
  //       <p>
  //         <u style={{ textDecorationColor: '#c2c923' }}>
  //           Maecenas eu lectus non est ultrices ultricies eu sed ligula. Sed mattis scelerisque
  //           sodales. Quisque sodales velit felis, vel porttitor ante placerat a. Donec a efficitur
  //           felis, sit amet gravida mi. Nam lacinia lacinia odio.
  //         </u>
  //       </p>
  //       Etiam ac tincidunt ipsum. Fusce blandit et arcu vitae finibus.
  //       <ul>
  //         <li>Pellentesque sit amet ante at turpis luctus ornare a ut tortor.</li>
  //         <li>
  //           Suspendisse vulputate leo velit, vel condimentum velit efficitur sit amet. Sed vitae
  //           aliquam quam. Sed at ullamcorper ante. In nibh urna, posuere in odio vitae, faucibus
  //           euismod nunc. Phasellus in hendrerit nisi. Cras porttitor libero aliquet, vehicula nisl
  //           eget, fermentum neque.
  //         </li>
  //       </ul>
  //       <p>
  //         Suspendisse vitae libero congue, venenatis lacus quis, eleifend elit. Donec sed ex
  //         fringilla, facilisis elit vel, ullamcorper ligula. Pellentesque ac urna scelerisque,
  //         pharetra lacus ut, volutpat <i>sem</i>.
  //       </p>
  //     </div>

  //     {/* <div className="my-5" style={{ borderTop: "2px solid #c2c923", width: '10%', margin: 'auto' }}></div>

  //     <div className='mt-4 px-3'>
  //        <p>{translations.filter((trans) => trans.code === 'ICS110').map((trans) => trans.description)}</p>
  //     </div> */}
  //   </div>
  // );

  return (
    <>
      {/* <div className='simple-box instruction'>
          <div className='row'>
            <div className='col-lg-12'>
              <Accordion className='fade-in-accordion' id='fade-in-accordion'>
                <Accordion.Collapse style={{ marginTop: '2rem' }} eventKey={1} timeout={1000}>
                  <Card.Body>{instructionSection}</Card.Body>
                </Accordion.Collapse>
                <Accordion.Toggle
                  as='h5'
                  className='accordion-title'
                  eventKey={1}
                  style={{ margin: '0' }}>
                  <div className='row p-2'>
                    <div className='col-10 px-3'>
                      <div
                        className='d-flex align-items-center text-uppercase'
                        style={{ height: '100%' }}>
                        Istruzioni
                      </div>
                    </div>
                    <div className='col-2 align-self-center pl-2 px-0 text-center'>
                      <ChevronRight className='accordion-arrow' />
                    </div>
                  </div>
                </Accordion.Toggle>
              </Accordion>
            </div>
          </div>
        </div>

        <h5 className='text-center' style={{ marginTop: '3rem', marginBottom: '3rem' }}>
          {translations.filter((trans) => trans.code === 'ICS116').map((trans) => trans.text)}
        </h5> */}
      {/* <p style={{color: 'grey'}}><IcoWarning style={{width: '20px'}} />&nbsp;tutte le domande sono obbligatorie</p> */}

      {instructionSection}
      <Form onSubmit={(e) => submit(e)} id='valutazione-aud-sroi-form'>
        {AUDProjects &&
          AUDProjects.length > 0 &&
          AUDProjects.map((thisAUDProject) => (
            <AUDProject
              key={thisAUDProject.id}
              currentAUDProject={thisAUDProject}
              translations={translations}
              projectStatus={project.status}
              handleChangesInAudProject={handleChangesInAudProject}
              handleDeleteInAudProject={handleDeleteInAudProject}
              scrollToInstructions={scrollToInstructions}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          ))}
      </Form>

      {project.status === 'DRAFT' && (
        <Button
          disabled={isLoading}
          variant='info'
          className='add-aud-button'
          onClick={handleAddAudProject}>
          <Row className='align-items-center py-1 px-3'>
            <IcoPlus className='light-color ico-small plus mr-1' />
            <span className='d-md-inline'>
              {translations.filter((trans) => trans.code === 'ICS092').map((trans) => trans.text)}
            </span>
          </Row>
        </Button>
      )}
    </>
  );
};
export default ValutazioneSROIAUD;
