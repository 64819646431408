import React, { Component } from 'react';

class Ico101 extends Component {
  render() {
    let className = 'extsvg ';
    if (this.props.className !== undefined) {
      className += this.props.className;
    }

    return (
      <svg
        id="ico-101"
        viewBox="0 0 117.62 136"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        height={this.props.height}
        width={this.props.width} //#000000
      >
        <path
          d="M58.8103 0L117.621 34V102L58.8103 136L0 102V34L58.8103 0Z"
          className="esagono"
        />
        <g transform="translate(130, 160) scale(3)" style={{transformOrigin: 'center'}}>
            {/* <path className='ico' fill="#000" d="M156.115 34.48l-36.94.586 10.02 28.995 27.258-2.324zM18 37.727V494h297.682L304 448l-64-64-48-160-88.055-80L96 64zM219.076 98.58L160 112l16.38 30.342 17.65 5.394-1.223 19.672L244.664 144zm34.656 60.99l-19.56 21.05 27.508 12.61 12.855-20.804zm50.907 90.002l-34.38 25.012-11.766 58.662 100.53 22.97-4.163-98.218zm107.475 62.373l-20.886 3.465-15.988 20.033 4.873 21.23 40.848-15.55zm-43.62 95.996l-44.32 5.87 6.858 29.422 36.875-1.396zm37.573 59.982l-32.103 5.375L340.113 494h79.508z"/> */}
            <g id="SVGRepo_bgCarrier" strokeWidth="0"/>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"/>
            <g id="SVGRepo_iconCarrier"> <rect x="14" y="23" style={{fill: 'white', stroke: 'white', strokeWidth: 0.96, strokeMiterlimit: 10}} width="4" height="4"/>
               <path style={{fill: 'white', stroke: 'white', strokeWidth: 0.96, strokeMiterlimit: 10}} d="M16,5c-3.866,0-7,2.686-7,6v2h4v-2 c0-1.105,1.343-2,3-2s3,0.877,3,2c0,3.321-5,3.782-5,8h4c0-2.629,5-3.039,5-8C23,7.727,19.866,5,16,5z"/>
            </g>
         </g>
      </svg>
    );
  }
}
export default Ico101;
