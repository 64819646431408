import React from 'react';
import { Col, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Trans } from '@lib/i18n';
import { rounder } from '@lib/helpers/index';

const ListItem = ({ value, textTooltip, style, className, type = '' }) => {
  const renderMyTooltip = (props) => {
    let message = '';

    //Sometimes, props.popper.state is undefined.
    //It runs this function enough times that state gets a value
    if (props.popper.state) {
      message = props.popper.state.options.testObj;
    }

    return (
      <Tooltip id='button-tooltip' {...props}>
        {message}
      </Tooltip>
    );
  };

  const renderValue = (value, textTooltip = '') => {
    if (textTooltip !== '') {
      return (
        <>
          <OverlayTrigger
            placement='top'
            delay={{ show: 200, hide: 400 }}
            overlay={renderMyTooltip}
            popperConfig={{ testObj: textTooltip }}>
            <span>{value}</span>
          </OverlayTrigger>
        </>
      );
    } else {
      return <span>{value}</span>;
    }
  };
  return type !== 'span' ? (
    <>
      <Col className={className} style={{ ...style }}>
        <span style={{ maxWidth: '30px' }}>
          {typeof value === 'number'
            ? renderValue(rounder(value), textTooltip)
            : renderValue(value, textTooltip)}
        </span>
      </Col>
    </>
  ) : (
    <span style={{ maxWidth: '30px' }}>
      {typeof value === 'number'
        ? renderValue(rounder(value), textTooltip)
        : renderValue(value, textTooltip)}
    </span>
  );
};

export default ListItem;
