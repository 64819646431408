import React, { useRef, useEffect, useState } from 'react';
import { Row, Col, Button, Nav, Navbar } from 'react-bootstrap';
import { downloadPDF } from 'components/form/downloadPDF';
import { Externalyticslogo, BancaFucinoLogo } from 'loghi';
import { getProject } from '@lib/api';
import logodesio from 'loghi/bancadesio_logo.png';

const Thanks = () => {
  const [assessments, setAssesments] = useState([]);
  const translations = JSON.parse(localStorage.getItem('translations'));
  const printRef = useRef();
  const params = new URLSearchParams(window.location.search);
  const ndg = params.get('ndg');
  const rapporto = params.get('rapporto');
  const date = params.get('date');
  const hours = params.get('hours');
  const id = params.get('id');
  //   console.log('NDG', assessments);

  useEffect(() => {
    getProject(id).then((res) => {
      setAssesments(
        res.esgassessments
          ?.map((ans) =>
            ans.answers.filter(
              (nestedAns) => nestedAns.requiresDocument === 1 && nestedAns.text !== 'No'
            )
          )
          .filter((ansArray) => ansArray.length > 0)
      );
    });
  }, [id]);

  const printCertificate = () => {
    //const div = document.getElementById('container-thanks');
    //div.style.margin = '1rem 0.5rem';
    downloadPDF(printRef.current, `ics_certificato_di_completamento_ndg${ndg}_nr${rapporto}`).then(
      () => {
        //div.style.margin = 'inherit';
      }
    );
  };

  return (
    <>
      {false && (
        <div>
          <header id='site-header'>
            <Navbar expand='lg' variant='light'>
              <Nav
                className='mr-auto left-side text-center'
                style={{ justifyContent: 'space-between' }}>
                <Navbar.Brand>
                  {/* <BancaFucinoLogo className='mainlogo' /> */}
                  <BancaFucinoLogo className='mainlogo' />
                </Navbar.Brand>
                <div className='powered-by-header'>
                  <small>Powered by</small>
                  <Externalyticslogo className='externalyticslogo' />
                </div>
              </Nav>
            </Navbar>
          </header>
          <Row className='row-thanks'>
            <Col className='first-col-thanks'>
              <div className='text-thanks' style={{ marginBottom: '3rem' }}>
                <p className='no-to-head'>
                  {translations
                    .filter((trans) => trans.code === 'ICS101')
                    .map((trans) => trans.text)}
                  &nbsp;{ndg}&nbsp;
                  {translations
                    .filter((trans) => trans.code === 'ICS102')
                    .map((trans) => trans.text)}
                  &nbsp;{rapporto}&nbsp;
                </p>
                <p>
                  {translations
                    .filter((trans) => trans.code === 'ICS103')
                    .map((trans) => trans.text)}
                  &nbsp;{date}&nbsp;
                  {translations
                    .filter((trans) => trans.code === 'ICS104')
                    .map((trans) => trans.text)}
                  &nbsp;{hours}
                </p>
                {/* <h1 className='text-uppercase'>{translations.filter(trans => trans.code === 'ICS098').map(trans => trans.text)}</h1>
                     <span>{translations.filter(trans => trans.code === 'ICS099').map(trans => trans.text)}</span> */}
              </div>
            </Col>
            <Col className='second-col-thanks'>
              <img src='/img/thanks-assessment.jpg' alt='thanks-img' className='img-thanks' />
            </Col>
          </Row>
        </div>
      )}

      <div className='parent-thanks'>
        <div id='container-thanks' ref={printRef} style={{ minHeight: '700px' }}>
          <div className='marquee'>
            {translations.filter((trans) => trans.code === 'ICS106').map((trans) => trans.text)}
          </div>
          <Row className='flex-nowrap justify-content-center mt-2 d-flex align-items-center'>
            <div className='logo-thanks'>
              {/* <BancaFucinoLogo className='mainlogo' style={{ minWidth: '300px', width: '300px' }} /> */}
              <img src={logodesio} alt='logo' style={{ maxWidth: '270px', width: '270px' }} />
            </div>
          </Row>
          <Row className='flex-nowrap align-items-center justify-content-center mt-5 px-4 mx-3'>
            <Col className='first-col-cert'>
              <div className={`content-thanks ${assessments.length > 0 && 'd-flex flex-column'}`}>
                <Col
                  // className={`assignment d-flex align-items-center ${
                  //   assessments.length > 0 ? 'flex-column col-3 ' : 'justify-content-around'
                  // }`}
                  style={{ fontSize: '1.2rem', textAlign: 'center' }}>
                  <div>
                    <p className='no-to-head'>
                      {translations
                        .filter((trans) => trans.code === 'ICS101')
                        .map((trans) => trans.text)}
                      &nbsp;{ndg}&nbsp;
                    </p>
                    <p className='no-to-head'>
                      {translations
                        .filter((trans) => trans.code === 'ICS102')
                        .map((trans) => trans.text)}
                      &nbsp;{rapporto}&nbsp;
                    </p>

                    <p className='no-to-head'>
                      {translations
                        .filter((trans) => trans.code === 'ICS103')
                        .map((trans) => trans.text)}
                      &nbsp;
                    </p>
                    <p className='no-to-head'>
                      {date}&nbsp;
                      {translations
                        .filter((trans) => trans.code === 'ICS104')
                        .map((trans) => trans.text)}
                      &nbsp;{hours}
                    </p>
                  </div>

                  <img
                    src='/img/thanks-assessment.jpg'
                    alt='thanks-img'
                    className='img-thanks'
                    style={{ maxHeight: '16rem' }}
                  />
                </Col>
                <Col>
                  {assessments.length > 0 && (
                    <>
                      <p style={{ fontSize: '1rem' }}>
                        Si ricorda di inviare i seguenti documenti:&nbsp;
                      </p>
                      <ul>
                        {assessments?.map((el) => {
                          return (
                            <li
                              className='text-left'
                              style={{ fontSize: '.8rem' }}
                              key={el[0].description}>
                              {el[0].description}
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  )}
                </Col>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <Row id='certificate-button' className='justify-content-center'>
        <Button variant='primary' onClick={() => printCertificate()}>
          {translations.filter((trans) => trans.code === 'ICS097').map((trans) => trans.text)}
        </Button>
      </Row>
    </>
  );
};

export default Thanks;
