import domtoimage from 'dom-to-image';
import { jsPDF } from 'jspdf';

export const downloadPDF = async (printRef, pdfName, filterClass) => {
  const element = printRef;
  //console.log('element', element);

  /* const handleFilter = (node) => {
      if (node.classList) return !node.classList.contains(filterClass);
      return true;
   } */

  const dataUrl = await domtoimage.toPng(element, {
    style: { backgroundColor: 'white' /* filter: filterClass ? handleFilter(printRef) : '' */ },
  });
  //console.log('dataUrl', dataUrl);
  var data = new Image();
  data.src = dataUrl;

  const max = { height: 300, width: 210 };

  var doc = new jsPDF('p', 'mm', 'a4', true);

  const pageWidth = doc.internal.pageSize.getWidth();
  //const pageHeight = doc.internal.pageSize.getHeight();
  const imgProperties = doc.getImageProperties(data);

  let canvasHeight = imgProperties.height;
  let canvasWidth = imgProperties.width;
  let src = data.src;
  let ratio = imgProperties.height / imgProperties.width;

  if (canvasHeight > max.height || canvasWidth > max.width) {
    if (canvasHeight > canvasWidth) {
      canvasHeight = max.height;
      canvasWidth = canvasHeight * (1 / ratio);
    } else if (canvasWidth > canvasHeight) {
      canvasWidth = max.width;
      canvasHeight = canvasWidth * ratio;
    }
  }

  const marginX = (pageWidth - canvasWidth) / 2; //per centrare orizzontalmente
  //const marginY = (pageHeight - canvasHeight) / 2;  //per centrare verticalmente

  doc.addImage(src, 'png', marginX, 2, canvasWidth, canvasHeight);
  //doc.addPage("p", "mm", "a4");

  doc.save(`${pdfName}.pdf`);
};
