import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Card,
  Button,
} from 'react-bootstrap';
import { getTranslations } from '@lib/api';
import { Ico97 } from '@icons/new';
import { TooltipInfoIcon, 
  Handshake,
  SocialNetworking,
  Sustainable,
 } from '@icons';

const MaterialityDetails = ({ currentProject, showCells, setShowCells }) => {
  const [translations, setTranslations] = useState([]);
  const [materiality, setMateriality] = useState([]);
  //console.log('currentProject', currentProject);
  //console.log('materiality', materiality);

  useEffect(() => {
    getTranslations().then((retrievedTranslation) => {
      setTranslations(retrievedTranslation);
    });

    if (currentProject.materialissuesmap) { 
      setMateriality(currentProject.materialissuesmap)
    };
  }, [currentProject.materialissuesmap]);

  const showHideCells = () => setShowCells(!showCells);

  return (
    <>
      {/* <Card className="cards-utente d-none d-lg-block mb-0 border-bottom-0 mt-0 border-header-materiality">
        <Card.Body>
          <Ico47 className="logo-card ico-box-blue" />
          <Card.Title className="text-uppercase pt-3">
            {translations.filter(trans => trans.code === 'ICS042').map(trans => trans.text)}
            <OverlayTrigger
              placement="right"
              delay={{ show: 200, hide: 400 }}
              overlay={<Tooltip id="button-tooltip">{translations.filter(trans => trans.code === 'ICS042').map(trans => trans.description)}</Tooltip>}
            >
              <TooltipInfoIcon className="tooltip-ico mb-1" />
            </OverlayTrigger>
          </Card.Title>
        </Card.Body>
      </Card>

      <Row className="text-center">
        <Col md={3}>
          <Card className="cards-utente d-none d-lg-block pb-0 mt-0 border-top-0 border-materiality" id="materiality-env">
            <Card.Body>
              <Card.Title className="text-capitalize pt-3">
                Environmental
              </Card.Title>
              <Sustainable className="my-5 svg-materiality" />

              <Row className="align-items-center justify-content-center flex-column align-content-center" id="materiality-env-list">
                {materiality.filter(mat => mat.esgcategory === 'E').map(filteredMat => (
                  <Col 
                    key={filteredMat.materialIssueCode} 
                    className="border border-2 m-2 p-3"
                    style={{color: filteredMat.outlook === 'red' || filteredMat.outlook === 'green'? 'white' : 'inherit', backgroundColor: filteredMat.outlook === 'green' ? '#689689' : filteredMat.outlook === 'yellow' ? '#D4BE31' : filteredMat.outlook === 'red' || filteredMat.outlook === 'green' ? '#C94723' : '#cdcdcd'}}
                  >
                    {filteredMat.text}
                  </Col>
                ))}
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <Col md={6}>
          <Card className="cards-utente d-none d-lg-block pb-0 mt-0 border-top-0 border-materiality" id="materiality-soc">
            <Card.Body>
              <Card.Title className="text-capitalize pt-3">
                Social
              </Card.Title>
              <SocialNetworking className="my-5 svg-materiality" />

              <Row className="align-items-center justify-content-center align-content-center" id="materiality-soc-list">
                {materiality.filter(mat => mat.esgcategory === 'S').map(filteredMat => (
                  <Col 
                    key={filteredMat.materialIssueCode} 
                    className="border border-2 m-2 p-3"
                    style={{color: filteredMat.outlook === 'red' || filteredMat.outlook === 'green' ? 'white' : 'inherit', backgroundColor: filteredMat.outlook === 'green' ? '#689689' : filteredMat.outlook === 'yellow' ? '#D4BE31' : filteredMat.outlook === 'red' || filteredMat.outlook === 'green' ? '#C94723' : '#cdcdcd'}}
                  >
                    {filteredMat.text}
                  </Col>
                ))}
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <Col md={3}>
          <Card className="cards-utente d-none d-lg-block pb-0 mt-0 border-top-0 border-materiality" id="materiality-gov">
            <Card.Body>
              <Card.Title className="text-capitalize pt-3">
                Governance
              </Card.Title>
              <Handshake className="my-5 svg-materiality" />

              <Row className="align-items-center justify-content-center flex-column align-content-center" id="materiality-gov-list">
                {materiality.filter(mat => mat.esgcategory === 'G').map(filteredMat => (
                  <Col 
                    key={filteredMat.materialIssueCode} 
                    className="border border-2 m-2 p-3"
                    style={{color: filteredMat.outlook === 'red' || filteredMat.outlook === 'green' ? 'white' : 'inherit', backgroundColor: filteredMat.outlook === 'green' ? '#689689' : filteredMat.outlook === 'yellow' ? '#D4BE31' : filteredMat.outlook === 'red' || filteredMat.outlook === 'green' ? '#C94723' : '#cdcdcd'}}
                  >
                    {filteredMat.text}
                  </Col>
                ))}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row> */}

      <Card className="cards-utente d-none d-lg-block mb-5" /* style={{ marginTop: '4rem'}} */>
        <Card.Body>
          <Ico97 className="logo-card ico-box-blue" />
          <Card.Title className="margin-title-box text-uppercase pt-3">
            {/* CONTRIBUTO ALLA MATERIALITÀ */}
            {translations.filter(trans => trans.code === 'ICS042').map(trans => trans.text)}
            <OverlayTrigger
              placement="right"
              delay={{ show: 200, hide: 400 }}
              overlay={<Tooltip id="button-tooltip">{translations.filter(trans => trans.code === 'ICS042').map(trans => trans.description)}</Tooltip>}
            >
              <TooltipInfoIcon className="tooltip-ico mb-1" />
            </OverlayTrigger>
          </Card.Title>

          <Row className="padding-legend-media mb-3">
            <Col id='materiality-legend' className="align-self-start">
              <Row className="flex-column px-4 no-to-head">
                <Row className="min-label-width align-items-center mb-1 mx-0">
                  <div className='box-materiality green-1'></div>
                  <span>score tra 60 e 100</span>
                </Row>
                
                <Row className="min-label-width align-items-center mb-1 mx-0">
                  <div className='box-materiality green-2'></div>
                  <span>score tra 50 e 60</span>
                </Row>
                
                <Row className="min-label-width align-items-center mb-1 mx-0">
                  <div className='box-materiality green-3'></div>
                  <span>score tra 30 e 50</span>
                </Row>

                <Row className="min-label-width align-items-center mb-1 mx-0">
                  <div className='box-materiality grey'></div>
                  <span>score tra 0 e 30</span>
                </Row>
              </Row>
            </Col>
          </Row>

          <Row className="px-4 align-items-center mt-1 mb-5 gap-title">
            <Col md={3} className="padding-media">
              <Row className="align-items-center">
                <Sustainable className="my-5 svg-materiality" /> <span className="f-600 env no-to-head">Environmental</span>
              </Row>
            </Col>

            <Col>
              <Row id="row-environmental">
                {materiality.filter(mat => mat.esgcategory === 'E').sort((filteredMatA, filteredMatB) => filteredMatB.miPerc - filteredMatA.miPerc)
                .map(filteredMat => (
                  <Col 
                    key={filteredMat.materialIssueCode} 
                    className="border border-2 m-2 p-3"
                    style={{color: filteredMat.outlook === 'green' || filteredMat.outlook === 'yellow' ? 'white' : filteredMat.outlook === 'red' ? 'black' : 'inherit', backgroundColor: filteredMat.outlook === 'green' ? '#689689' : filteredMat.outlook === 'yellow' ? '#82B58F' : filteredMat.outlook === 'red' || filteredMat.outlook === 'green' ? '#9CD394' : '#cdcdcd'}}
                  >
                    <span style={{fontSize: '1.1rem'}}>{filteredMat.text}</span>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>

          <Row className="px-4 align-items-center mb-5 gap-title">
            <Col md={3} className="padding-media">
              <Row className="align-items-center">
                <SocialNetworking className="my-5 svg-materiality" /><span className="f-600 soc no-to-head">Social</span>
              </Row>
            </Col>

            <Col>
              <Row id="row-social">
                {materiality.filter(mat => mat.esgcategory === 'S').sort((filteredMatA, filteredMatB) => filteredMatB.miPerc - filteredMatA.miPerc)
                .map((filteredMat, index) => (
                  <Col 
                    key={filteredMat.materialIssueCode} 
                    className="border border-2 m-2 p-3 fade-in-div materiality-block"
                    style={{display: index >= 8 ? !showCells ? 'none' : 'block' : 'block', color: filteredMat.outlook === 'green' || filteredMat.outlook === 'yellow' ? 'white' : filteredMat.outlook === 'red' ? 'black' :'inherit', backgroundColor: filteredMat.outlook === 'green' ? '#689689' : filteredMat.outlook === 'yellow' ? '#82B58F' : filteredMat.outlook === 'red' || filteredMat.outlook === 'green' ? '#9CD394' : '#cdcdcd'}}
                  >
                    <span style={{fontSize: '1.1rem'}}>{filteredMat.text}</span>
                  </Col>
                ))}  
              </Row>

              <Row>
                <Button variant="link" id="show-more" className="f-500 no-to-head" onClick={() => showHideCells()}>Mostra di&nbsp;{showCells ? 'meno' : 'più'}...</Button>
              </Row>    
            </Col>
          </Row>

          <Row className="px-4 align-items-center mb-4 gap-title">
            <Col md={3} className="padding-media">
              <Row className="align-items-center">
                <Handshake className="my-5 svg-materiality" /> <span className="f-600 gov no-to-head">Governance</span>
              </Row>
            </Col>

            <Col>
              <Row id="row-governance">
                {materiality.filter(mat => mat.esgcategory === 'G').sort((filteredMatA, filteredMatB) => filteredMatB.miPerc - filteredMatA.miPerc)
                .map(filteredMat => (
                  <Col 
                    key={filteredMat.materialIssueCode} 
                    className="border border-2 m-2 p-3"
                    style={{color: filteredMat.outlook === 'green' || filteredMat.outlook === 'yellow' ? 'white' : filteredMat.outlook === 'red' ? 'black' : 'inherit', backgroundColor: filteredMat.outlook === 'green' ? '#689689' : filteredMat.outlook === 'yellow' ? '#82B58F' : filteredMat.outlook === 'red' || filteredMat.outlook === 'green' ? '#9CD394' : '#cdcdcd'}}
                  >
                    <span style={{fontSize: '1.1rem'}}>{filteredMat.text}</span>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default MaterialityDetails;
