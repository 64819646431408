import React, { useState, useEffect, Fragment } from 'react';
import { Form, Button, OverlayTrigger, Tooltip, InputGroup } from 'react-bootstrap';
import { 
  createSurvey,
} from '@lib/api';
import { TooltipInfoIcon, IcoWarning } from '@icons';
import { DangerToast } from '@components/GlobalToastList';
import Thanks from 'components/survey/Thanks';

const QuestionComponent = ({ question, projectStatus, disablingAnswers, disablingQuestions, questions, setDisablingAnswers,
  setDisablingQuestions,
  handleChecked,
  handleValue,
  handleSurveyDataChange,
  clearValidity }) => {

  useEffect(() => {
    let copyOfDisablingAnswers = disablingAnswers; //id answer: ref question id
    let copyOfDisablingQuestions = disablingQuestions; //id question: [answers with ref e true/false]

    if(question.dependsOnAnswer) {

      if (copyOfDisablingAnswers[question.dependsOnAnswer] === undefined) { copyOfDisablingAnswers[question.dependsOnAnswer] = [];}

      //per popolare disablingAnswers
      copyOfDisablingAnswers[question.dependsOnAnswer].push(document.getElementById(`questionID-${question.id}`));

      //per popolare disablingQuestions
      questions.forEach((questionNested, indexNested) => {
        if (questionNested.answers.some(answ => answ.id === question.dependsOnAnswer)) {
          const questionID = questions[indexNested].id;

          if (copyOfDisablingQuestions[questionID] === undefined) { copyOfDisablingQuestions[questionID] = [];}
      
          copyOfDisablingQuestions[questionID] = questions[indexNested].answers.map((answ) => ({
            id: answ.id,
            answForShow: Object.keys(disablingAnswers).some(answID => Number(answID) === answ.id),
            divNames: [...disablingQuestions[questionID].filter(el => el.id === answ.id).map(el => el.divNames).flat(), document.getElementById(`questionID-${question.id}`)],
          }));
        };
      });

    }; 

    if (copyOfDisablingAnswers && copyOfDisablingQuestions) {
      setDisablingAnswers(copyOfDisablingAnswers);
      setDisablingQuestions(copyOfDisablingQuestions);
    }
  }, [disablingAnswers, disablingQuestions, question.dependsOnAnswer, question.id, questions, setDisablingAnswers, setDisablingQuestions])

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {question.description}
    </Tooltip>
  );

  return (
      <div 
        id={`questionID-${question.id}`} 
        name={`questionName-${question.id}`} 
        questionid={question.id} 
      >
        <hr className="hr-question"/> 
        <div className="row justify-content-around">
          {/*<div className="col-3">
              <span className='question-code'>  
              {question.id}
            </span>   
          </div>*/}

          <div className="col-12 text-center">
            {question.text} 

            {question.description ? (<OverlayTrigger
              placement="top"
              delay={{ show: 200, hide: 400 }}
              overlay={renderTooltip}
            >
              <TooltipInfoIcon className="tooltip-ico" style={{width: '1rem'}} />
            </OverlayTrigger>) : null}

            {/* {question.id} */}
          </div>    

          {/* <div className="col-2">
          </div>   */}      
        </div>

        <div className="row mt-4">
          {/* <div className="col-3">
          </div> */}

          <div className="col-12">
            {( question.type==='radio' || question.type==='checkbox') && 
              <div key={`default-radio-${question.id}`} className="mb-3 center-input">
                {question.answers.map(answer => 
                  (<Fragment key={`answer-radiocheck-${answer.id}`}>
                    <Form.Check 
                      required={question.dependsOnAnswer ? false : true}
                      type={question.type}
                      id={`${answer.id}`}
                      name={`${question.id}`}
                      label={`${answer.text}`}
                      dependsonquestion={`${question.dependsOnQuestion}`}
                      dependsonanswer={`${question.dependsOnAnswer}`}
                      onChange={e => handleSurveyDataChange(e)}
                      defaultChecked={handleChecked(question.id, answer.id)}
                      className={`answer-${answer.id}`}
                      onInvalid={e => e.target.setCustomValidity(question.type === 'checkbox' ? "Seleziona una o più di queste opzioni" : "Seleziona una di queste opzioni")}
                      onInput={clearValidity(question.id)}
                      disabled={projectStatus === 'DELETED' || projectStatus === 'SUBMITTED' ? true : false}
                    />
                    {/* {answer.id} */}
                  </Fragment>
                ))} 
              </div>
            }

            {( question.type==="input-num") && 
              <div key={`default-inputnum-${question.id}`} className="mb-3 center-input">
                {question.answers.map((answer) => (
                  <Form.Group className="mb-3 text-center" key={`answer-num-${answer.id}`}>
                    <Form.Label>{answer.text}</Form.Label>
                    {/* {answer.id} */}
                    <Form.Control 
                      required={question.dependsOnAnswer ? false : true}
                      type="number" 
                      id={`${answer.id}`}
                      name={`${question.id}`}
                      min={0}
                      defaultValue={handleValue(question.id, answer.id)}
                      dependsonquestion={`${question.dependsOnQuestion}`}
                      dependsonanswer={`${question.dependsOnAnswer}`}
                      onBlur={e => handleSurveyDataChange(e)}
                      style={{ maxWidth: '10rem', textAlign: 'left'}}
                      disabled={projectStatus === 'DELETED' || projectStatus === 'SUBMITTED' ? true : false}
                    />
                  </Form.Group>
                ))}
              </div>
            }

            {( question.type==="input-perc") && 
              <div key={`default-inputperc-${question.id}`} className="mb-3 center-input">
                {question.answers.map((answer) => (
                  <Form.Group className="mb-3 pr-5" key={`answer-perc-${answer.id}`}>
                    <InputGroup>
                      {/* {answer.id} */}
                      <Form.Control 
                        required={question.dependsOnAnswer ? false : true}
                        type="number"
                        id={`${answer.id}`}
                        name={`${question.id}`}
                        defaultValue={handleValue(question.id, answer.id)}
                        dependsonquestion={`${question.dependsOnQuestion}`}
                        dependsonanswer={`${question.dependsOnAnswer}`}
                        min="0" 
                        max="100" 
                        onBlur={e => handleSurveyDataChange(e)}
                        disabled={projectStatus === 'DELETED' || projectStatus === 'SUBMITTED' ? true : false}
                      />
                      <InputGroup.Text className="addon-for-number">&#37;</InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                ))}
              </div>
            }

            {( question.type==="input-text") && 
              <div key={`default-inputtext-${question.id}`} className="mb-3 center-input">
                {question.answers.map((answer) => (
                  <Form.Group className="mb-3 pr-5" key={`answer-text-${answer.id}`}>
                    <InputGroup>
                      {/* {answer.id} */}
                      <Form.Control 
                        required={question.dependsOnAnswer ? false : true}
                        type="number"
                        id={`${answer.id}`}
                        name={`${question.id}`}
                        defaultValue={handleValue(question.id, answer.id)}
                        dependsonquestion={`${question.dependsOnQuestion}`}
                        dependsonanswer={`${question.dependsOnAnswer}`}
                        min="0" 
                        max="100" 
                        onBlur={e => handleSurveyDataChange(e)}
                        disabled={projectStatus === 'DELETED' || projectStatus === 'SUBMITTED' ? true : false}
                      />
                    </InputGroup>
                  </Form.Group>
                ))}
              </div>
            }
          </div>

          {/* <div className="col-2">
          </div>  */} 
        </div>
      </div>
  );
};

const SurveySROI = ({ questionsSurvey, surveyUuid, projectCondition, translations }) => {
  //console.log('questionsSurvey', questionsSurvey);
  const [questions, setQuestions] = useState([]);
  const [surveyData, setSurveyData] = useState([]);
  const [projectStatus, setProjectStatus] = useState(null);
  const [disablingAnswers, setDisablingAnswers] = useState({});
  const [disablingQuestions, setDisablingQuestions] = useState({});
  const [questionsNotRequested, setQuestionsNotRequested] = useState({});
  const [showThanks, setShowThanks] = useState(false);
  //console.log('questions', questions);
  //console.log('disablingAnswers', disablingAnswers);
  //console.log('disablingQuestions', disablingQuestions);
  //console.log('surveyData', surveyData);
  //console.log('projectStatus', projectStatus);

  useEffect(() => {
    setQuestions(questionsSurvey); 

    if (projectCondition) setProjectStatus(projectCondition);
  }, [projectCondition, questionsSurvey]);

  useEffect(() => {
    let tempState = {};

    if (questions.length > 0) { 
      questions.forEach(question => { 
        if (question.dependsOnAnswer) { 
          if (tempState[question.id] === undefined) { tempState[question.id] = [] };
          tempState[question.id] = question.answers.map(answ => answ.id);
        }
      })
    }

    setQuestionsNotRequested(tempState);
  }, [questions]);

  useEffect(() => {
    handleChecked();
    handleValue();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChecked = (questionID, answerID) => {
    let boolean = false;

    surveyData.forEach(el => {
      if (Number(el.questionId) === questionID) {
        const checkedAnswers = el.answers.filter(answer => answer.answerId === answerID);

        if (checkedAnswers.length > 0) {
          boolean = true
        } else {
          boolean = false
        }
      }
    });

    return boolean;
  };

  const handleValue = (questionID, answerID) => {
    let value;

    if (surveyData.length > 0) {
      surveyData.filter(sur => sur.questionId === questionID)
        .forEach(filteredAsse => {
          filteredAsse.answers.filter(answ => answ.answerId === answerID).forEach(filteredAnsw => {
            value = filteredAnsw.value;
          });
        });
    }

    return value;
  };
  
  const clearValidity = (name) => {
    const inputs = document.getElementsByName(name);

    inputs.forEach(input => input.setCustomValidity(''))
  };

  const handleSurveyDataChange = e => {
    //console.log('e.target', e.target);
    const questionID = Number(e.target.name);
    const answerID = Number(e.target.id);
    let tempState = [...surveyData];

    switch (e.target.type)
    {
    case 'radio':
      if (surveyData.some(sur => sur.questionId === questionID)) {
        surveyData.forEach((sur, index) => {  
          if (sur.questionId === questionID) {
            tempState[index].answers = [{answerId: answerID, value: e.target.value}];        
          }
        });

      } else {
        tempState.push({questionId: questionID, answers: [{answerId: answerID, value: e.target.value}]})
      }

      break;
    case 'checkbox':
      if (e.target.checked) {
        if (surveyData.some(sur => sur.questionId === questionID)) {

          surveyData.forEach((sur, index) => {  
            if (sur.questionId === questionID) {
              tempState[index].answers.push({answerId: answerID, value: e.target.value});       
            }
          });

        } else {
          tempState.push({questionId: questionID, answers: [{answerId: answerID, value: e.target.value}]})
        }

        const checkboxesList = document.getElementsByName(questionID);

        checkboxesList.forEach(checkbox => {
          if (Number(checkbox.id) === answerID) {
            checkbox.required = true;
          } else { checkbox.required = false }
        });

      } else {
       surveyData.forEach((sur, index) => {
          if (sur.questionId === questionID) {

            if (sur.answers.length > 0) {
              sur.answers.forEach((answ, i) => {
                if (answ.answerId === answerID) {
                  tempState[index].answers.splice(i, 1);

                  if (tempState[index].answers.length === 0) { tempState.splice(index, 1) }
                }
              })
            }
          }
        });

        const checkboxesList = [...document.getElementsByName(questionID)];

        if (checkboxesList.some(div => div.checked)) {
          document.getElementById(answerID).required = false;
        } else {
          checkboxesList.forEach(checkbox => checkbox.required = true)
        }
      }

      break;
    case 'text':
    case 'number':
      if (e.target.value) {
        if (surveyData.some(sur => sur.questionId === questionID)) {
          surveyData.forEach((sur, index) => {  

            if (sur.questionId === questionID) {
              if (tempState[index].answers.some(answ => answ.answerId === answerID)) {
                tempState[index].answers.forEach((answ, indexAnsw) => {
                  if (answ.answerId === answerID) {
                    tempState[index].answers[indexAnsw] = {answerId: answerID, value: e.target.value};
                  }
                })
              } else {
                tempState[index].answers.push({answerId: answerID, value: e.target.value});
              }
            }
          });
        } else {
          tempState.push({questionId: questionID, answers: [{answerId: answerID, value: e.target.value}]})
        }
      } else {
        surveyData.forEach((sur, index) => {
          if (sur.questionId === questionID) {
            tempState.splice(index, 1);
          }
        });    
      }
      
      break;
    default:
      console.log('Sorry');
    }

    Object.keys(disablingQuestions).forEach(disablingQuestion => {
      if (e.target.name === disablingQuestion) {
        disablingQuestions[disablingQuestion].forEach(answ => {

          if (answ.id === answerID && answ.answForShow) {

            //show
            disablingAnswers[answerID].forEach(ref => {

              if (ref.classList.contains('hide')) {
                const divName = ref.getAttribute("id");
  
                document.getElementById(divName).classList.remove('hide');
                document.getElementById(divName).classList.add('fade-in-div');  
                document.getElementById(divName).classList.remove('fade-out-div'); 
              }
            })
            
          } else if (answ.id === answerID && !answ.answForShow) {

            const divs = disablingQuestions[disablingQuestion].filter(answ => answ.id === answerID)
              .map(answ => answ.divNames).flat();

            const divNames = divs.map(div => div.getAttribute("id"));

            const nameIds = divs.map(div => div.getAttribute("questionid"));

            //hide
            divNames.forEach(divName => {
              document.getElementById(divName).classList.remove('fade-in-div');  
              document.getElementById(divName).classList.add('fade-out-div'); 
            })
            
            setTimeout(() => {
              divNames.forEach(divName => {
                document.getElementById(divName).classList.add('hide');
              })

              const inputsToUncheck = nameIds.map(nameId => document.getElementsByName(nameId));

              inputsToUncheck.forEach(inputs => {
                inputs.forEach(input => {
                  input.checked = false;
                  input.required = false;
                })
              })

            }, 100);

            tempState = tempState.filter(sur => {
              return !nameIds.some(nameId => {
                return sur.questionId === Number(nameId);
              });
            });
          };

        });
      };
    });
      
    setSurveyData(tempState);


    if ((e.target.checked || e.target.value) && 
      Object.keys(disablingAnswers).some(answerId => Number(answerId) === answerID)) {
        const questionIDsDivs = disablingAnswers[answerID].map(div => div.id.replace('questionID-', ''));

        questionIDsDivs.forEach(questionId => {
          questionsNotRequested[questionId].forEach(divID => {
            document.getElementById(divID).required = true;
          })
        })
    };

  };

  const submit = async (e) => {
    e.preventDefault();

    let request = [];

    surveyData.forEach(el => {   
      request.push({questionId: Number(el.questionId), answers: el.answers.map(answ => ({
          answerId: Number(answ.answerId),
          value: answ.value,
        })
      )})
    });

    const result = await createSurvey(surveyUuid, request);
    //console.log('result submit', result);

    if (result) {
      setShowThanks(true);
    } else {
      DangerToast('Attenzione', "Errore nell'invio del sondaggio");
    }
  };

  return (
    <>
    {!showThanks && (
      <>
        <div className="survey-description">
          <h5>{translations.filter(trans => trans.code === 'ICS036').map(trans => trans.text)}</h5>
          <p style={{color: 'grey'}}><IcoWarning style={{width: '20px'}} />&nbsp;tutte le domande sono obbligatorie</p>
        </div>
        <Form onSubmit={e => submit(e)} id="survey-form">
          {questions.sort((questA, questB) => questA.id - questB.id).map(question => { return (
              <QuestionComponent 
                key={question.id} 
                question={question} 
                disablingAnswers={disablingAnswers} 
                disablingQuestions={disablingQuestions} 
                questions={questions} 
                projectStatus={projectStatus}
                setDisablingAnswers={setDisablingAnswers}
                setDisablingQuestions={setDisablingQuestions}
                handleChecked={handleChecked}
                handleValue={handleValue}
                handleSurveyDataChange={handleSurveyDataChange}
                clearValidity={clearValidity}
              />
            )})
          }
          <Button 
            variant="primary" 
            className="assessment-button" 
            type="submit"
          >
            SALVA
          </Button>
        </Form>
      </>
      )}
      {showThanks && <Thanks />}
    </>
  );
};
export default SurveySROI;
