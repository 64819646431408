import React, { useState, useEffect } from 'react';
import { Card, Accordion } from 'react-bootstrap';
import { Ico101 } from '@icons/new';
import { ChevronRight } from '@icons';

const Faq = () => {
   const [translations, setTranslations] = useState([]);
   const [faqTranslations, setFaqTranslations] = useState([]);

   useEffect(() => {
      const tempTranslations = JSON.parse(localStorage.getItem('translations'));

      setTranslations(tempTranslations);
      setFaqTranslations(tempTranslations.filter(tran => tran.code.includes(`FAQ_`)));
   }, []);

   const buildSection = (code) => {
      let filteredFaq = faqTranslations.filter(tran => tran.code.includes(`FAQ_${code}_`));

       return <div className='ml-5' style={{ marginBottom: '5rem'}}>
            <h5 className='section-title'>{faqTranslations.filter(trad => trad.code === `FAQ_${code}`).map(trad => trad.text)}</h5>
            {filteredFaq.map((trad, indexTrad) => {
               return <Accordion key={trad.code} className="my-4">
                  <Accordion.Collapse eventKey={indexTrad + 1}>  
                     <div className="row faq-text-format mt-4">
                        <div className="col-11">
                           {trad.description}
                        </div>
                     </div>   
                  </Accordion.Collapse>
                  <Accordion.Toggle as="h5" className="accordion-title" eventKey={indexTrad + 1} style={{ margin: '0' }}>
                     <div className="row flex-nowrap w-100">
                        <div className="col-12 px-3">
                           <div className="row">
                              <div className="col-auto title-simple-box" style={{maxWidth: '96%'}}>
                                 {indexTrad + 1}. {trad.text}
                              </div>

                              <div className="col pl-2">
                                 <ChevronRight className="faq accordion-arrow" />
                              </div>
                           </div>
                        </div>
                     </div>
                  </Accordion.Toggle>
               </Accordion>
            })}
         </div>
   };

   return (
      <>
         <h2 className='title-faq-section'>{translations.filter(trans => trans.code === 'ICS113').map(trans => trans.text)}</h2>
         
         <div className='faq-section'>
            <Card style={{ marginTop: '4rem', marginBottom: '4rem'}}>
               <Card.Body>
                  <Ico101 className="logo-card ico-box-blue" />
                  <Card.Title className="margin-title-box pt-3"></Card.Title>

                  {buildSection('GEN')}
                  {buildSection('DAT')}
                  {buildSection('ESG')}
                  {buildSection('SROI')}
               </Card.Body>
            </Card>
         </div>
      </>
   );
};
export default Faq;