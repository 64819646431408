import React, { useState, useEffect } from 'react';
import {  OverlayTrigger, Tooltip,  } from 'react-bootstrap';
import { translate  } from './translation.js';


import { TooltipInfoIcon } from '@icons';




const OETrans = ({code}) => {

  //se text TRUE ritorna solo il text tradotto
  //se description TRUE  ritorna solo il description tradotto
  // true tutte e due ritorna un oggetto { text }

  const[trans, setTrans] = useState({})

    useEffect( () => {

      translate(code).then( trans => {

        setTrans(trans)

      })

    },[code])

    
    const renderTooltip = (props) => {
        let message = "";
    
        if (props.popper.state) {
          message = props.popper.state.options.testObj
        };
    
        return (
          <Tooltip id="button-tooltip" {...props}>
            {message}
          </Tooltip>
        )
      };


  return (
    <>
    {trans.text}
            <OverlayTrigger
               placement="top"
               delay={{ show: 200, hide: 400 }}
               overlay={renderTooltip}
               popperConfig={{testObj: trans.description}}
            >
               <TooltipInfoIcon className="tooltip-ico" style={{width: '1rem'}} />
            </OverlayTrigger>
    </>
  );
};

export default OETrans;
