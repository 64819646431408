import React from 'react';
import { Home } from '@components';
import { useLocation } from 'react-router-dom';

const HomePage = (props) => {
  const location = useLocation();
  return (
    <div id="home">
      <Home referrer={location?.state?.referrer} />
    </div>
  );
};

export default HomePage;
