

export async function  translate(code) {


    let lang = localStorage.getItem('userLanguage') ?? 'IT';
    let translations = localStorage.getItem('translations');

    if (!translations) {

         return { text : code , description : code };


    }

    translations =JSON.parse(translations);

    let trans = translations && translations.length>0  ? translations.filter((obj) => obj.lang === lang && obj.code===code ).pop() : { text : code , description : code };;
    trans = trans ? trans : { text : code , description : code };
    return  trans;


}