import React, { useState, useEffect, Fragment } from 'react';
import {
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Card,
} from 'react-bootstrap';
import { getTranslations, getSroiRatingsParams, getTypologicalAverage } from '@lib/api';
//import { Trans } from '@lib/i18n';
import { Ico21 } from '@icons/new';
import { TooltipInfoIcon } from '@icons';

const SroiDetails = ({ currentProject, sectorCode, proponentCode }) => {
  const [translations, setTranslations] = useState([]);
  const [sroiRatings, setSroiRatings] = useState({});
  const [sroiParams, setSroiParams] = useState([]);
  const [sroiTypologicalAverage, setSroiTypologicalAverage] = useState('');
  //console.log('sroiRatings', sroiRatings);
  //console.log('sroiParams', sroiParams);

  useEffect(() => {
    getTranslations().then((retrievedTranslation) => {
      setTranslations(retrievedTranslation);
    });

    getSroiRatingsParams().then(retrievedSroiParams => {
      setSroiParams(retrievedSroiParams);
    });

    if (currentProject.sroiratings) { 
      setSroiRatings({
        vanBenefits: currentProject.sroiratings.filter(rating => rating.code === 'VANBENEFITS').map(filtredRating => filtredRating.value.toLocaleString('it-IT', {maximumFractionDigits: 0})),
        vanCosts: currentProject.sroiratings.filter(rating => rating.code === 'VANCOSTS').map(filtredRating => filtredRating.value.toLocaleString('it-IT', {maximumFractionDigits: 0})),
        vane: currentProject.sroiratings.filter(rating => rating.code === 'VANE').map(filtredRating => filtredRating.value.toLocaleString('it-IT', {maximumFractionDigits: 0})),
        tire: currentProject.sroiratings.filter(rating => rating.code === 'TIRE').map(filtredRating => filtredRating.value.toString().replace('.', ',')),
        sroi: currentProject.sroiratings.filter(rating => rating.code === 'SROI').map(filtredRating => filtredRating.value.toString().replace('.', ',')),
        totalCapex: currentProject.sroiratings.filter(rating => rating.code === 'TOTALCAPEX').map(filtredRating => filtredRating.value.toLocaleString('it-IT', {maximumFractionDigits: 0})),
      })
    };

    if (currentProject.id) {
      getTypologicalAverage(currentProject.id).then((retrievedTypologicalAverage) => {
         setSroiTypologicalAverage(retrievedTypologicalAverage.code === 200 ? retrievedTypologicalAverage.response.avgSroiRating.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : 'N/A');
      });
    }
  }, [currentProject.sroiratings, currentProject.id]);

  const renderTooltip = (props) => {
    let message = "";

    if (props.popper.state) {
      message = props.popper.state.options.testObj
    };

    return (
      <Tooltip id="button-tooltip" {...props}>
        {message}
      </Tooltip>
    )
  };

  return (
    <>
      <Card className="cards-utente h-95 d-none d-lg-block" style={sectorCode === 'EVENTI' ? { marginTop: '4rem'} : null}>
        <Card.Body>
          <Ico21 className="logo-card ico-box-blue" />
          <Card.Title className="margin-title-box text-uppercase pt-3">
            {translations.filter(trans => trans.code === 'ICS041').map(trans => trans.text)}
            <OverlayTrigger
              placement="right"
              delay={{ show: 200, hide: 400 }}
              overlay={<Tooltip id="button-tooltip">{translations.filter(trans => trans.code === 'ICS041').map(trans => trans.description)}</Tooltip>}
            >
              <TooltipInfoIcon className="tooltip-ico mb-1" />
            </OverlayTrigger>
          </Card.Title>

          <Row className="mb-0 mt-4" id="sroi-details">
            <Col>
              {/* benefici economici */}
              <Row className="border-bottom">
                <Col className="f-600 basis-fit-content pr-0">
                  {sroiParams.filter(sroiParam => sroiParam.code === 'VANBENEFITS').map(filteredSroiParam => {
                    return (<Fragment key={filteredSroiParam.code}>
                    {filteredSroiParam.text}
                    {filteredSroiParam.description ? (<OverlayTrigger
                      placement="right"
                      delay={{ show: 200, hide: 400 }}
                      overlay={renderTooltip}
                      popperConfig={{testObj: filteredSroiParam.description}}
                    >
                      <TooltipInfoIcon className="tooltip-ico" style={{width: '1rem'}} />
                    </OverlayTrigger>) : null}
                    </Fragment>)
                  })}
                </Col>
                <Col className="text-right basis-fit-content pl-0">{sroiRatings.vanBenefits?.length > 0 ? `€ ${sroiRatings.vanBenefits}` : 'N/A'}</Col>
              </Row>

              {/* costi economici */}
              <Row className="border-bottom">
                <Col className="f-600 basis-fit-content pr-0">
                  {sroiParams.filter(sroiParam => sroiParam.code === 'VANCOSTS').map(filteredSroiParam => {
                    return (<Fragment key={filteredSroiParam.code}>
                    {filteredSroiParam.text}
                    {filteredSroiParam.description ? (<OverlayTrigger
                      placement="right"
                      delay={{ show: 200, hide: 400 }}
                      overlay={renderTooltip}
                      popperConfig={{testObj: filteredSroiParam.description}}
                    >
                      <TooltipInfoIcon className="tooltip-ico" style={{width: '1rem'}} />
                    </OverlayTrigger>) : null}
                    </Fragment>)
                  })}
                </Col>
                <Col className="text-right basis-fit-content pl-0">{sroiRatings.vanCosts?.length > 0 ? `€ ${sroiRatings.vanCosts}` : 'N/A'}</Col>
              </Row>

              {/* vane */}
              <Row className="border-bottom">
                <Col className="f-600 basis-fit-content pr-0">
                  {sroiParams.filter(sroiParam => sroiParam.code === 'VANE').map(filteredSroiParam => {
                    return (<Fragment key={filteredSroiParam.code}>
                    {filteredSroiParam.text}
                    {filteredSroiParam.description ? (<OverlayTrigger
                      placement="right"
                      delay={{ show: 200, hide: 400 }}
                      overlay={renderTooltip}
                      popperConfig={{testObj: filteredSroiParam.description}}
                    >
                      <TooltipInfoIcon className="tooltip-ico" style={{width: '1rem'}} />
                    </OverlayTrigger>) : null}
                    </Fragment>)
                  })}
                </Col>
                <Col className="text-right basis-fit-content pl-0">{sroiRatings.vane?.length > 0 ? `€ ${sroiRatings.vane}` : 'N/A'}</Col>
              </Row>

              {/* tire */}
              <Row className="border-bottom">
                <Col className="f-600 basis-fit-content pr-0">
                  {sroiParams.filter(sroiParam => sroiParam.code === 'TIRE').map(filteredSroiParam => {
                    return (<Fragment key={filteredSroiParam.code}>
                    {filteredSroiParam.text}
                    {filteredSroiParam.description ? (<OverlayTrigger
                      placement="right"
                      delay={{ show: 200, hide: 400 }}
                      overlay={renderTooltip}
                      popperConfig={{testObj: filteredSroiParam.description}}
                    >
                      <TooltipInfoIcon className="tooltip-ico" style={{width: '1rem'}} />
                    </OverlayTrigger>) : null}
                    </Fragment>)
                  })}
                </Col>
                <Col className="text-right basis-fit-content pl-0">{sroiRatings.tire?.length > 0 ? `${sroiRatings.tire}%` : 'N/A'}</Col>
              </Row>

              {/* sroi */}
              <Row className="border-bottom">
                <Col className="f-600 basis-fit-content pr-0">
                  {sroiParams.filter(sroiParam => sroiParam.code === 'SROI').map(filteredSroiParam => {
                    return (<Fragment key={filteredSroiParam.code}>
                    {filteredSroiParam.text}
                    {filteredSroiParam.description ? (<OverlayTrigger
                      placement="right"
                      delay={{ show: 200, hide: 400 }}
                      overlay={renderTooltip}
                      popperConfig={{testObj: filteredSroiParam.description}}
                    >
                      <TooltipInfoIcon className="tooltip-ico" style={{width: '1rem'}} />
                    </OverlayTrigger>) : null}
                    </Fragment>)
                  })}
                </Col>
                <Col className="text-right basis-fit-content pl-0">{sroiRatings.sroi?.length > 0 ? sroiRatings.sroi : 'N/A'}</Col>
              </Row>

              {/* Ammontare dell'investimento */}
              <Row>
                <Col className="f-600 pr-0 col-9">
                  {translations.filter(trans => trans.code === 'ICS061').map(trans => trans.text)}
                </Col>
                <Col className="text-right pl-0">{sroiTypologicalAverage}</Col>
              </Row>

              {proponentCode === 'ET' ? <Row>
                <Col className="f-600 pr-0 invisible">
                  {/* invisible */}#
                </Col>
                <Col className="text-right pl-0 invisible">{/* invisible */}#</Col>
              </Row> : null}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default SroiDetails;
