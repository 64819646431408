import React, { useState, useEffect } from 'react';
import { getSurveyQuestions, getProjectStatusByUUID, getTranslations } from '@lib/api';
import { SurveySROI } from '@components/survey';

const Survey = (props) => {
  const [questionsSurvey, setQuestionsSurvey] = useState(null);
  const [projectCondition, setProjectCondition] = useState(null);
  const [translations, setTranslations] = useState([]);
  const surveyUuid = props.match.params.surveyId;
  //console.log('surveyUuid', surveyUuid);
  //console.log('projectCondition', projectCondition);

  useEffect(() => {
    if (surveyUuid) {
      getTranslations().then((retrievedTranslation) => {
        setTranslations(retrievedTranslation);
      });

      getSurveyQuestions(surveyUuid).then((retrievedSurvey) => {
        setQuestionsSurvey(retrievedSurvey);
      });

      getProjectStatusByUUID(surveyUuid).then((retrievedStatus) => {
        setProjectCondition(retrievedStatus);
      });
    }
  }, [surveyUuid]);

  if (!questionsSurvey) return null;
  return (
    <>
      <div id="survey">
        <div className="container-fluid">
          <SurveySROI 
            questionsSurvey={questionsSurvey} 
            surveyUuid={surveyUuid} 
            projectCondition={projectCondition} 
            translations={translations} 
          />
        </div>
      </div>
    </>
  );
};

export default Survey;
