import React from 'react';
import { ProjectsCompanyTab } from '@components/projects';
import { useParams } from 'react-router-dom';
const Aziende = () => {
  const { company } = useParams();
  return (
    <div id='aziende' className='megaContent pt-0'>
      <div className='container-fluid'>
        <ProjectsCompanyTab id={company} />
      </div>
    </div>
  );
};

export default Aziende;
