import React, { useEffect, useRef, useCallback } from 'react';
import * as d3 from 'd3';

const RadarChartGradient = ({ data, radarColor, secondRadarColor, divId, maxValue }) => {
  const [divWidth, setDivWidth] = React.useState(0);
  const resizeObserver = new ResizeObserver((entries) => {
    entries.forEach((entry) => {
      setDivWidth(entry.contentRect.width);
    });
  });

  useEffect(() => {
    const startObserving = () => {
      const radarDiv = document.querySelector('#radarSroi');
      if (radarDiv) {
        resizeObserver.observe(radarDiv);
      }
    };

    if (data.length > 0) {
      // resizeObserver.observe(document.querySelector('#radarSroi'));
      startObserving();
    }
    return () => {
      resizeObserver.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  let maxValuex = Math.ceil(maxValue / 7) * maxValue;
  // let maxValuex = Math.ceil(maxValue / 7) * 7;

  //console.log('data', data);
  //console.log('data', data);

  const svgRef = useRef();

  const drawChart = useCallback(
    (svg) => {
      let margin = { top: 5, right: 100, bottom: 40, left: 100 },
        width = Math.min(divWidth, window.innerWidth - 10) - margin.left - margin.right,
        height = Math.min(width, window.innerHeight - margin.top - margin.bottom - 20),
        wrapWidth = (divWidth * 20) / 100,
        labelFactor = divWidth / ((divWidth * 70) / 100); //1.25 circa

      if (window.screen.width >= 3000) {
        width = width - 300;
        height = height - 300;
        labelFactor = labelFactor * 1.15;
      } else if (window.screen.width >= 1920) {
        width = width - 50;
        height = height + 25;
      } else if (window.screen.width <= 1366) {
        width = width + 30;
        height = height + 70;
      }

      width = 180;
      height = 180;

      let color = d3.scaleBand().range(['#739CC4', '#CC333F', '#00A0B0']);

      let radarChartOptions = {
        w: width,
        h: height,
        margin: margin,
        //maxValue: `${Math.round(d3.max(data[0], (d) => d.value)).toFixed()}`, //100 maxValuex
        //levels: 5, //10
        roundStrokes: false,
        color: color,
        wrapWidth: wrapWidth,
        labelFactor: labelFactor,
      };

      if (data.length > 0) {
        //Call function to draw the Radar chart
        RadarChart('.radarChart', data, radarChartOptions);
      }

      function RadarChart(id, data, options) {
        let cfg = {
          w: 600, //Width of the circle
          h: 600, //Height of the circle
          margin: { top: 20, right: 20, bottom: 20, left: 20 }, //The margin of the SVG
          levels: maxValue, //How many levels or inner circles should there be drawn
          maxValue: maxValuex, //What is the value that the biggest circle will represent
          labelFactor: 1.25, //How much farther than the radius of the outer circle should the labels be placed
          wrapWidth: 130, //The number of pixels after which a label needs to be given a new line
          opacityArea: 0.1, //The opacity of the area of the blob //0.1
          dotRadius: 5, //The size of the colored circles of each blog
          opacityCircles: 0.4, //The opacity of the circles of each blob //0.1
          strokeWidth: 2, //The width of the stroke around each blob
          roundStrokes: false, //If true the area and stroke will follow a round path (cardinal-closed)
          color: d3.scaleBand(d3.schemeCategory10), //Color function
        };

        //Put all of the options into a variable called cfg
        if ('undefined' !== typeof options) {
          for (let i in options) {
            if ('undefined' !== typeof options[i]) {
              cfg[i] = options[i];
            }
          }
        }

        /* //If the supplied maxValue is smaller than the actual one in data, replace by the maxValue
      let maxValue = Math.min(
        cfg.maxValue,
        d3.max(data, function (i) {
          return d3.max(
            i.map(function (o) {
              return o.value;
            })
          );
        })
      ); */

        let allAxis = data[0].map((scope) => {
            return scope.axis;
          }), //Names of each axis
          total = allAxis.length, //The number of different axes
          radius = Math.min(cfg.w / 2, cfg.h / 2), //Radius of the outermost circle
          //Format = d3.format('%'), //Percentage formatting
          angleSlice = (Math.PI * 2) / total; //The width in radians of each "slice"

        //Scale for the radius
        let rScale = d3.scaleLinear().range([0, radius]).domain([0, cfg.maxValue]);

        //////////// Create the container SVG and g /////////////

        //Remove whatever chart with the same id/class was present before

        svg.selectAll('*').remove();

        //Initiate the radar chart SVG
        svg
          .attr('width', cfg.w + cfg.margin.left + cfg.margin.right)
          .attr('height', cfg.h + cfg.margin.top + cfg.margin.bottom)
          .attr('class', 'radar' + id)
          .style('overflow', 'visible');

        //Append a g element
        let g = svg.append('g').attr(
          'transform',
          //'translate(' + (cfg.w / 2 + cfg.margin.left) + ',' + (cfg.h / 2 + cfg.margin.top) + ')'
          'translate(80, 80)'
        );

        //add background gradient
        let backGradient = svg
          .append('defs')
          .append('radialGradient')
          .attr('id', 'green-red')
          .attr('x1', '0%')
          .attr('y1', '0%')
          .attr('x2', '100%')
          .attr('y2', '0%');

        const dataStop = [
          { offset: '10%', stopColor: '#C94723', stopOpacity: '1' }, //red
          { offset: '30%', stopColor: '#666666', stopOpacity: '0.2' }, //yellow
          { offset: '50%', stopColor: '#666666', stopOpacity: '0.2' }, //green
          { offset: '50%', stopColor: '#666666', stopOpacity: '0.2' }, //green
          { offset: '50%', stopColor: '#666666', stopOpacity: '0.2' }, //green
        ];

        /* const dataStop = [
         {offset: '0%', stopColor: '#C94723', stopOpacity: '0.8'}, //red
         {offset: '50%', stopColor: '#D4BE31', stopOpacity: '1'}, //yellow
         {offset: '100%', stopColor: '#689689', stopOpacity: '1'}, //green
      ]  */

        backGradient
          .selectAll('stop')
          .data(dataStop)
          .enter()
          .append('stop')
          .attr('offset', function (d, i) {
            return d.offset;
          })
          .attr('stop-color', function (d, i) {
            return d.stopColor;
          })
          .attr('stop-opacity', function (d, i) {
            return d.stopOpacity;
          });

        ////////// Glow filter ///////////

        //Filter for the outside glow
        let filter = g.append('defs').append('filter').attr('id', 'glow');

        filter.append('feGaussianBlur').attr('stdDeviation', '2.5').attr('result', 'coloredBlur');

        let feMerge = filter.append('feMerge');

        feMerge.append('feMergeNode').attr('in', 'coloredBlur');

        feMerge.append('feMergeNode').attr('in', 'SourceGraphic');

        /////////////// Draw the Circular grid //////////////////

        //Wrapper for the grid & axes
        let axisGrid = g.append('g').attr('class', 'axisWrapper');

        //Draw the background circles
        axisGrid
          .selectAll('.levels')
          .data(d3.range(1, cfg.levels + 1).reverse())
          .enter()
          .append('circle')
          .attr('class', 'gridCircle')
          .attr('r', function (d, i) {
            return (radius / cfg.levels) * d;
          })
          .style('fill', 'url(#green-red)') //color of circles background //#ebebeb #green-red
          .style('stroke', '#ebebeb')
          .style('fill-opacity', cfg.opacityCircles);
        //.style('filter', 'url(#glow)');

        //Text indicating at what % each level of circle is
        axisGrid
          .selectAll('.axisLabel')
          .data(d3.range(1, cfg.levels + 1).reverse())
          .enter()
          .append('text')
          .attr('class', 'axisLabel')
          .attr('x', 4)
          .attr('y', function (d) {
            return (-d * radius) / cfg.levels;
          })
          .attr('dy', '0.4em')
          .style('font-size', '0.75rem') //font-size of percentage
          //.attr('fill', '#D3D3D3') //color of text in circles % #C2C923
          .text(function (d, i) {
            return (d * (cfg.maxValue / cfg.levels)).toLocaleString('it-IT'); //+ '%'
          });

        //////////////////// Draw the axes //////////////////////

        //Create the straight lines radiating outward from the center
        let axis = axisGrid
          .selectAll('.axis')
          .data(allAxis)
          .enter()
          .append('g')
          .attr('class', 'axis');
        //Append the lines
        axis
          .append('line')
          .attr('x1', 0)
          .attr('y1', 0)
          .attr('x2', function (d, i) {
            return rScale(cfg.maxValue * 1.1) * Math.cos(angleSlice * i - Math.PI / 2);
          })
          .attr('y2', function (d, i) {
            return rScale(cfg.maxValue * 1.1) * Math.sin(angleSlice * i - Math.PI / 2);
          })
          .attr('class', 'line')
          .style('stroke', 'white')
          .style('stroke-width', '2px');

        //Append the labels at each axis
        axis
          .append('text')
          .attr('class', 'legend')
          //.style('font-size', '14.4px')
          .style(
            'font-size',
            window.screen.width >= 3000
              ? (divWidth * 2) / 100 + 'px'
              : (divWidth * 3.3) / 100 + 'px'
          )
          .attr('text-anchor', 'middle')
          .attr('dy', '0em')
          .attr('x', function (d, i) {
            //const newLabelFactor = d === 'ENTI TERRITORIALI' || d === 'UNIVERSITÀ E CUS' || d === 'PARROCCHIE' ? cfg.labelFactor + 0.3 : cfg.labelFactor;
            const newLabelFactor = cfg.labelFactor + 0.2;
            return rScale(cfg.maxValue * newLabelFactor) * Math.cos(angleSlice * i - Math.PI / 2);
          })
          .attr('y', function (d, i) {
            //const newLabelFactor = d === 'PARROCCHIE' ? (window.screen.width >= 3000 ? cfg.labelFactor - 0.40 : cfg.labelFactor - 0.25) : cfg.labelFactor;
            const newLabelFactor = cfg.labelFactor;
            return rScale(cfg.maxValue * newLabelFactor) * Math.sin(angleSlice * i - Math.PI / 2);
          })
          .text(function (d) {
            return d;
          })
          .call(wrap, cfg.wrapWidth);

        ///////////// Draw the radar chart blobs ////////////////

        //The radial line function
        let radarLine = d3
          .lineRadial()
          .curve(d3.curveLinearClosed)
          .radius(function (d) {
            return rScale(d.value > cfg.maxValue ? cfg.maxValue : d.value < 0 ? 0 : d.value);
          })
          .angle(function (d, i) {
            return i * angleSlice;
          });

        if (cfg.roundStrokes) {
          radarLine.curve(d3.curveCardinalClosed);
        }

        //Create a wrapper for the blobs
        let blobWrapper = g
          .selectAll('.radarWrapper')
          .data(data)
          .enter()
          .append('g')
          .attr('class', 'radarWrapper');

        //Append the backgrounds
        blobWrapper
          .append('path')
          .attr('class', 'radarArea')
          .attr('d', function (d, i) {
            return radarLine(d);
          })
          .style('fill', function (d, i) {
            return cfg.color(i);
          })
          .style('fill-opacity', cfg.opacityArea)
          .on('mouseover', function (d, i) {
            //Dim all blobs
            d3.selectAll('.radarArea')
              .transition()
              .duration(200)
              //.style('fill-opacity', 0.1)
              .style('background-color', 'rgba(69, 77, 93, 0.9)')
              .style('padding', '0.2rem 0.4rem')
              .style('border-radius', '0.1rem')
              .style('color', '#fff');
            //Bring back the hovered over blob
            d3.select(this).transition().duration(200).style('fill-opacity', 0.7);
          })
          .on('mouseout', function () {
            //Bring back all blobs
            d3.selectAll('.radarArea')
              .transition()
              .duration(200)
              .style('fill-opacity', cfg.opacityArea);
          });

        //Create the outlines
        blobWrapper
          .append('path')
          .attr('class', 'radarStroke')
          .attr('d', function (d, i) {
            return radarLine(d);
          })
          .style('stroke-width', cfg.strokeWidth + 'px')
          .style('stroke', function (d, i) {
            return cfg.color(i);
          })
          .style('fill', 'none') //color of graph
          .style('opacity', 0.4) //opacity color of graph
          .style('filter', 'url(#glow)');

        //Append the circles
        blobWrapper
          .selectAll('.radarCircle')
          .data(function (d, i) {
            return d;
          })
          .enter()
          .append('circle')
          .attr('class', 'radarCircle')
          .attr('r', cfg.dotRadius)
          .attr('cx', function (d, i) {
            return (
              rScale(d.value > cfg.maxValue ? cfg.maxValue : d.value < 0 ? 0 : d.value) *
              Math.cos(angleSlice * i - Math.PI / 2)
            );
          })
          .attr('cy', function (d, i) {
            return (
              rScale(d.value > cfg.maxValue ? cfg.maxValue : d.value < 0 ? 0 : d.value) *
              Math.sin(angleSlice * i - Math.PI / 2)
            );
          })
          .style('fill', function (d, i, j) {
            return cfg.color(j);
          })
          .style('fill-opacity', 0.8);

        //////// Append invisible circles for tooltip ///////////

        //Wrapper for the invisible circles on top
        let blobCircleWrapper = g
          .selectAll('.radarCircleWrapper')
          .data(data)
          .enter()
          .append('g')
          .attr('class', 'radarCircleWrapper');

        //Append a set of invisible circles on top for the mouseover pop-up
        blobCircleWrapper
          .selectAll('.radarInvisibleCircle')
          .data(function (d, i) {
            return d;
          })
          .enter()
          .append('circle')
          .attr('class', 'radarInvisibleCircle')
          .attr('r', cfg.dotRadius * 1.2)
          .attr('cx', function (d, i) {
            return (
              rScale(d.value > cfg.maxValue ? cfg.maxValue : d.value < 0 ? 0 : d.value) *
              Math.cos(angleSlice * i - Math.PI / 2)
            );
          })
          .attr('cy', function (d, i) {
            return (
              rScale(d.value > cfg.maxValue ? cfg.maxValue : d.value < 0 ? 0 : d.value) *
              Math.sin(angleSlice * i - Math.PI / 2)
            );
          })
          .style('fill', function (d, i) {
            if (d.type === 'avg') {
              return '#053D58';
            } else {
              return '#378288';
            }
            // if (d.type === 'avg') {
            //   return d.value < 1 ? '#C43567' : '#222288';
            // } else {
            //   return d.value < 1 ? '#C94723' : '#689689';
            // }
          }) //'none' color of dots
          .style('pointer-events', 'all')
          .on('mouseover', function (e, d) {
            let newX = parseFloat(d3.select(this).attr('cx')) + 10;
            let newY = parseFloat(d3.select(this).attr('cy')) - 10;

            tooltip
              .attr('x', newX)
              .attr('y', newY)
              .text(
                d.type !== 'avg'
                  ? `Media Sroi ${d.axis}: ${
                      divId === 'radarBenefits' ? '€' : '€ '
                    }${d.value.toLocaleString('it-IT', {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}`
                  : `Deviazione Standard ${d.axis}: ${
                      divId === 'radarBenefits' ? '€' : '€ '
                    }${d.value.toLocaleString('it-IT', {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}`
              )
              .attr('fill', 'white')
              .attr('stroke-width', '0px')
              .attr('font-weight', 'normal')
              .attr('font-size', '15px')
              .attr('font-family', 'Barlow, sans-serif')
              .transition()
              .duration(200)
              .style('opacity', 1);
          })
          .on('mouseout', function () {
            tooltip.transition().duration(200).style('opacity', 0);
          });

        //Filter for background text tooltip
        let filterTooltip = g.append('defs').append('filter').attr('id', 'background-tooltip');

        filterTooltip
          .append('feFlood')
          .attr('flood-color', 'rgba(69, 77, 93, 0.9)') //background-color tooltip grey
          .attr('stroke-linejoin', 'round')
          .attr('result', 'background-tooltip');

        filterTooltip.append('feGaussianBlur').attr('stdDeviation', '0'); //border-radius + blur tooltip

        filterTooltip
          .append('feComponentTransfer')
          .append('feFuncA')
          .attr('type', 'table')
          .attr('tableValues', '0 0 0 1');

        filterTooltip.append('feComposite').attr('operator', 'over').attr('in', 'SourceGraphic');

        let filterMerge1 = filterTooltip.append('feMerge');

        filterMerge1.append('feMergeNode').attr('in', 'txtBackground');

        filterMerge1.append('feMergeNode').attr('in', 'SourceGraphic');

        //Set up the small tooltip for when you hover over a circle
        let tooltip = g
          .append('text')
          .attr('class', 'tooltipRadar')
          .attr('filter', 'url(#background-tooltip)')
          .style('opacity', 0);

        /////////////////// Helper Function /////////////////////

        //Wraps SVG text
        function wrap(text, width) {
          text.each(function () {
            let text = d3.select(this),
              words = text.text().split(/\s+/).reverse(),
              word,
              line = [],
              lineNumber = 0,
              lineHeight = 1.4, // ems
              y = text.attr('y'),
              x = text.attr('x'),
              dy = parseFloat(text.attr('dy')),
              tspan = text
                .text(null)
                .append('tspan')
                .attr('x', x)
                .attr('y', y)
                .attr('dy', dy + 'em');

            while ((word = words.pop())) {
              line.push(word);
              tspan.text(line.join(' '));
              if (tspan.node().getComputedTextLength() > width) {
                line.pop();
                tspan.text(line.join(' '));
                line = [word];
                tspan = text
                  .append('tspan')
                  .attr('x', x)
                  .attr('y', y)
                  .attr('dy', ++lineNumber * lineHeight + dy + 'em')
                  .text(word);
              }
            }
          });
        } //wrap
      } //RadarChart
    },
    [data, divWidth, radarColor, divId, maxValuex]
  );

  useEffect(() => {
    if (svgRef.current) {
      const svg = d3.select(svgRef.current);
      drawChart(svg);
    }
  }, [svgRef, divWidth, drawChart]);

  return (
    <svg
      className='radarChart container'
      ref={svgRef}
      style={{ maxWidth: '10rem' /* maxHeight: '11rem' */ }}
      preserveAspectRatio='xMinYMin meet'
      //{...(window.screen.width >= 3000 ? {viewBox: "0 0 600 300"} : {viewBox: "0 0 200 200"})}
    />
  );
};
export default RadarChartGradient;
