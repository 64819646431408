import React, { useState } from 'react';
import {
  // Link,
  withRouter,
} from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import { ReactComponent as Menuoutline } from '@icons/menu-outline.svg';
import { ReactComponent as Externalyticslogo } from 'loghi/externalytics_logo.svg';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { Button } from 'react-bootstrap';

import { ImpactAnalysis } from './nav';

function Menuaccordion() {
  const [activeId, setActiveId] = useState('0');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <Accordion defaultActiveKey='0'>
      <Card className={activeId === '0' ? 'panel-wrap active-panel container-panel' : 'panel-wrap'}>
        <Card.Header>
          <Accordion.Toggle
            as={Button}
            variant='link'
            eventKey='0'
            onClick={() => toggleActive('0')}
            className='panel-toggle'
            block>
            Impact analisys
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey='0'>
          <Card.Body className='pb-0'>
            <ImpactAnalysis />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card className={activeId === '1' ? 'panel-wrap active-panel container-panel' : 'panel-wrap'}>
        <Card.Header>
          <Accordion.Toggle
            as={Button}
            variant='link'
            eventKey='1'
            onClick={() => toggleActive('1')}
            className='panel-toggle'
            block>
            Country framework
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey='1'>
          <Card.Body className='pb-0'>Hello! I'm another body</Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card className={activeId === '2' ? 'panel-wrap active-panel container-panel' : 'panel-wrap'}>
        <Card.Header>
          <Accordion.Toggle
            as={Button}
            variant='link'
            eventKey='2'
            onClick={() => toggleActive('2')}
            className='panel-toggle'
            block>
            Non Financial kpi
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey='2'>
          <Card.Body className='pb-0'>Hello! I'm another body</Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card className={activeId === '3' ? 'panel-wrap active-panel container-panel' : 'panel-wrap'}>
        <Card.Header>
          <Accordion.Toggle
            as={Button}
            variant='link'
            eventKey='3'
            onClick={() => toggleActive('3')}
            className='panel-toggle'
            block>
            Development programs
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey='3'>
          <Card.Body className='pb-0'>Hello! I'm another body</Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

class Contentmenu extends React.Component {
  showSettings(event) {
    event.preventDefault();
  }

  render() {
    // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
    return (
      <Menu
        id={'sidebar-left'}
        className={'left-side-menu'}
        burgerButtonClassName={'btn-open-menu'}
        crossButtonClassName={'btn-close-menu'}
        customBurgerIcon={<Menuoutline className='menu-outline' />}
        customCrossIcon={<Menuoutline className='menu-outline' />}
        itemListElement='div'>
        <a id='home' className='home-item' href='/'>
          <Externalyticslogo className='mainlogo' />
        </a>
        <Menuaccordion />
      </Menu>
    );
  }
}

function Sidebarmenu() {
  return (
    <aside id='sidebar-mobile'>
      <Contentmenu />
    </aside>
  );
}

export default withRouter(Sidebarmenu);
