import React, { useState, useEffect, useCallback, useRef, Fragment } from 'react';
import { date } from '@lib/helpers';
import {
  FormControl,
  ButtonToolbar,
  InputGroup,
  Spinner,
} from 'react-bootstrap';
import { Lens } from '@icons';
import { getUsers } from '@lib/api';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import AG_GRID_LOCALE_IT from '@components/projects/ag-grid-translate/locale-it';

const UsersList = () => {
  const [users, setUsers] = useState([]);
  const [optionsPagination, setOptionsPagination] = useState([]);
  const gridRef = useRef();
  const [refresh, setRefresh] = useState(false);
  const localeText = AG_GRID_LOCALE_IT;
  //console.log('users', users);

  useEffect(() => {
    getUsers(2).then((retrievedUsers) => setUsers(retrievedUsers)); //lista solo utenti di ruolo 2
  }, []);

  useEffect(() => {
    setOptionsPagination([
      <Fragment key="options">
        <option defaultValue="20" key="20">20</option>
        <option value="50" key="50">50</option>
        <option value="100" key="100">100</option>
      </Fragment>
    ])  
  }, [users]);

  useEffect(() => {
    if (refresh) {
      getUsers(2).then((retrievedUsers) => {
        setUsers(retrievedUsers);
      });

      setRefresh(false);
    }
  }, [refresh]);

  const onFilterTextBoxChanged = useCallback((e) => {
    gridRef.current.api.setQuickFilter(e.target.value);
  }, []);

  const onFirstDataRendered = useCallback(() => {
    //gridRef.current.api.sizeColumnsToFit();
  }, []);

  const onPageSizeChanged = useCallback(() => {
    let value = document.getElementById('page-size').value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  const defaultColDef = {
    resizable: true,
    wrapText: true,
    autoHeight: true,
    flex: 1,
    suppressMovable: true,
  };

  const columnDefs = [
    {
      headerName: 'ID',
      field: 'id',
      filter: 'agTextColumnFilter',
      maxWidth: 110,
    },
    {
      headerName: 'Email',
      field: 'email',
      filter: 'agTextColumnFilter',
      minWidth: 430,
    },
    {
      headerName: 'Creato il',
      field: 'createdAt',
      filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: ['contains', 'inRange'],
        defaultOption: 'inRange',
      },
      valueGetter: (params) => {
        const formattedDate = date(params.data.createdAt);
        return formattedDate;
      },
    },
    {
      headerName: 'Aggiornato il',
      field: 'updatedAt',
      filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: ['contains', 'inRange'],
        defaultOption: 'inRange',
      },
      valueGetter: (params) => {
        const formattedDate = date(params.data.updatedAt);
        return formattedDate;
      },
    },
  ];

  return (
    <>
      <div className="row d-flex align-items-center mb-3 pl-4">
        <div className="col-6">
          <h5 className="title-simple-box">
            Lista utenti
          </h5>
        </div>
        <div className="col-6 text-right">
          <ButtonToolbar className="justify-content-end">
            {/* <Button variant="info" href="/valutazione">
              <Row className="align-items-center py-1 px-3">
                <IcoPlus className="light-color ico-small plus mr-2" />
                <span className="d-md-inline">Nuovo Progetto</span>
              </Row>
            </Button> */}
            <InputGroup className="search-table align-items-center">
              <FormControl
                type="text"
                id="filter-text-box"
                placeholder="Search..."
                className="d-none d-md-block "
                onInput={(e) => onFilterTextBoxChanged(e)}
              />
              <Lens />
            </InputGroup>
          </ButtonToolbar>
        </div>
      </div>
      <div className="row  pl-4">
        <div className="col-12" id="grid-wrapper">
          
          {Object.keys(users).length > 0 ?
            <div className="ag-theme-alpine projects-list-table">
              <AgGridReact
                ref={gridRef}
                columnDefs={columnDefs}
                rowData={users}
                defaultColDef={defaultColDef}
                domLayout="autoHeight"
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
                tooltipMouseTrack={true}
                onFirstDataRendered={onFirstDataRendered}
                pagination={true}
                paginationPageSize={20}
                headerHeight={50}
                rowHeight={50}
                localeText={localeText}
              />
              <div className="example-header">
                Utenti per pagina:&nbsp;
                <select onChange={onPageSizeChanged} id="page-size">
                  {optionsPagination}
                </select>
              </div>
            </div> : 
            <div className="text-center my-4">
              <Spinner animation="border" role="status">
                <span style={{visibility: 'hidden'}}>Loading...</span>
              </Spinner>
            </div>
          }
            
        </div>
      </div>
    </>
  );
};

export default UsersList;