import React, { useState, useEffect, useCallback, useRef, Fragment } from 'react';
import * as d3 from 'd3';
import { rounder } from '@lib/helpers';
import {
  Button,
  FormControl,
  ButtonToolbar,
  InputGroup,
  Row,
  Col,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { Lens, IcoPlus, TooltipInfoIcon, DownloadFile } from '@icons';
import {
  getTranslations,
  getStats,
  getProjects,
  getInterventions,
  getFinancings,
  getStructureClusters,
  exportExcel,
} from '@lib/api';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import AG_GRID_LOCALE_IT from '@components/projects/ag-grid-translate/locale-it';
import ActionBtnRenderer from '@components/projects/actionBtnRenderer/ActionBtnRenderer';
import RadarChartGradient from '@components/projects/radarChart/RadarChart';
import Scenari from 'pages/ico';
import ListItem from 'components/DashboardListItem';
import { useLocation } from 'react-router-dom';
const AnimatedDonutChart = ({ chartId, data, scoreEsg }) => {
  console.log('datass', scoreEsg);
  const plotData = [];

  if (Object.values(data).every((num) => num === 0)) {
    plotData.push(1);
    data = { empty: 1 };
  } else {
    Object.keys(data).forEach((key) => {
      /* if (data[key] !== 0) {
        plotData.push(data[key]);
      } */
      plotData.push(data[key]);
    });
  }

  useEffect(() => {
    let colors = data.empty ? ['#e5e5e5'] : ['#689689', '#D4BE31', '#C94723'];

    let sizes = {
      innerRadius: 75,
      outerRadius: 100,
    };

    let durations = {
      entryAnimation: 2000,
    };

    d3.select(`#${chartId}`).html('');

    let generator = d3.pie().padAngle(0.04).sort(null);

    let chart = generator(plotData);

    let arcs = d3
      .select(`#${chartId}`)
      .append('g')
      .attr('transform', 'translate(100, 100)')
      .selectAll('path')
      .data(chart)
      .enter()
      .append('path')
      .style('fill', (d, i) => colors[i])
      .attr('data-value', (d, i) => d.data)
      .attr('data-label', (d, i) => {
        const label = Object.keys(data).find((key) => data[key] === d.data);
        return label;
      });

    let g = d3.select(`#${chartId}`).selectAll('g');

    g.append('text')
      .attr('text-anchor', 'middle')
      .attr('font-size', '2.1rem')
      .attr('y', 12)
      .attr('font-weight', 'bold')
      .text(scoreEsg);
    // .append('tspan') // Append a tspan for the additional value
    // .attr('x', 0)
    // .attr('dy', '1.2em') // Adjust the vertical spacing as needed
    // .attr('font-size', '1.3rem')
    // .attr('opacity', '0.7') // Smaller font size for the additional value
    // .text('(' + scoreEsgNoOut + ')');
    //number in circle

    let angleInterpolation = d3.interpolate(generator.startAngle()(), generator.endAngle()());

    let innerRadiusInterpolation = d3.interpolate(0, sizes.innerRadius);
    let outerRadiusInterpolation = d3.interpolate(0, sizes.outerRadius);

    let arc = d3.arc();

    arcs
      .transition()
      .duration(durations.entryAnimation)
      .attrTween('d', (d) => {
        let originalEnd = d.endAngle;
        return (t) => {
          let currentAngle = angleInterpolation(t);
          if (currentAngle < d.startAngle) {
            return '';
          }

          d.endAngle = Math.min(currentAngle, originalEnd);

          return arc(d);
        };
      });

    d3.select(`#${chartId}`)
      .transition()
      .duration(durations.entryAnimation)
      .tween('arcRadii', () => {
        return (t) =>
          arc.innerRadius(innerRadiusInterpolation(t)).outerRadius(outerRadiusInterpolation(t));
      })
      .on('end', () => {
        //console.log(InvestmentChartIcon)
        //d3.select(`#${chartId}`).node().append()
      });

    const tooltip = d3
      .select('body')
      .append('div')
      .attr('class', 'svg-tooltip')
      .style('position', 'absolute')
      .style('visibility', 'hidden');

    const mouseEnterCallback = (d) => {
      d3.select(d.target).attr('stroke-width', '2').attr('stroke', d.target.style.fill);

      tooltip
        .style('visibility', 'visible')
        .text(
          data.empty ? 'non applicabile' : `${rounder(d.target.attributes['data-value'].value)}%`
        );
    };

    const mouseLeaveCallback = (d) => {
      d3.select(d.target).attr('stroke-width', '0');
      tooltip.style('visibility', 'hidden');
    };

    d3.select(`#${chartId}`)
      .selectAll('path')
      .on('mouseenter', mouseEnterCallback)
      .on('mousemove', (event) => {
        tooltip.style('top', `${event.pageY}px`).style('left', `${event.pageX + 10}px`);
      })
      .on('mouseleave', mouseLeaveCallback);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <svg
        id={chartId}
        preserveAspectRatio='xMinYMin meet'
        viewBox='0 0 200 200'
        style={{ maxWidth: '11rem' }}></svg>
    </div>
  );
};

const esgLabel = ['E', 'S', 'G', 'ESG'];

const optionsLabel = [
  {
    value: 'PROFIT',
    label: 'PROFIT',
    name: 'Profit',
  },
  {
    value: 'NOPROFIT',
    label: 'NO PROFIT',
    name: 'No Profit',
  },
  {
    value: 'ET',
    label: 'ENTI TERRITORIALI',
    name: 'Enti Territoriali',
  },
  {
    value: 'ECCL',
    label: 'PARROCCHIE',
    name: 'Parrocchie',
  },
  {
    value: 'UNI',
    label: 'UNIVERSITÀ E CUS',
    name: 'Università e Cus',
  },
  {
    value: 'AUD',
    label: 'AUDIOVISIVI',
    name: 'Audiovisivi',
  },
  {
    value: 'portfolio',
    label: 'PORTAFOGLIO',
    name: 'Portafoglio',
  },
  {
    value: 'LIGHTNOPRO',
    label: 'NO PROFIT LIGHT',
    name: 'No Profit Light',
  },
  {
    value: 'LIGHTPRO',
    label: 'PROFIT LIGHT',
    name: 'Profit Light',
  },
];

/* const dataTest = [
   {axis: 'PARROCCHIE', value: -0.5},
   {axis: 'AUDIOVISIVI', value: 0.7},
   {axis: 'ENTI TERRITORIALI', value: 1},
   {axis: 'NO PROFIT', value: 3},
   {axis: 'UNIVERSITÀ E CUS', value: 4},
   {axis: 'PROFIT', value: 546756587854},
]; */

/* const dataTest1 = [
   {axis: 'PARROCCHIE', value: -2},
   {axis: 'AUDIOVISIVI', value: 3},
   {axis: 'ENTI TERRITORIALI', value: 0},
   {axis: 'NO PROFIT', value: 1.5},
   {axis: 'UNIVERSITÀ E CUS', value: 3},
   {axis: 'PROFIT', value: 7},
]; */

const ProjectsCompanyTab = () => {
  const [translations, setTranslations] = useState([]);
  const [projects, setProjects] = useState(null);
  const [interventions, setInterventions] = useState([]);
  const [financing, setFinancing] = useState([]);
  const [cluster, setCluster] = useState([]);
  const [optionsPagination, setOptionsPagination] = useState([]);
  const [stats, setStats] = useState({});
  const [dataRating, setDataRating] = useState(0);
  //const [dataRisk, setDataRisk] = useState(0);
  //const [dataMateriality, setDataMateriality] = useState(0);
  const [avgRating, setAvgRating] = useState('');
  const [scoreEsg, setScoreEsg] = useState('');
  const [dataBenefits, setDataBenefits] = useState([]);
  const [dataSroi, setDataSroi] = useState([]);
  const [avgBenefits, setAvgBenefits] = useState([]);
  const [avgData, setAvgData] = useState([]);
  const [noOutData, setNoOutData] = useState([]);
  const gridRef = useRef();
  const avgGridRef = useRef();
  const [refresh, setRefresh] = useState(false);
  const localeText = AG_GRID_LOCALE_IT;
  // const [radarDivWidth, setRadarDivWidth] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const containsAzienda = location.pathname.includes('aziende');
  console.log('AVG', avgData);
  //console.log('projects', projects);
  // console.log('scoreEsg ', scoreEsg);
  // console.log('dataRating', dataRating);
  // console.log('stats ', stats);
  // console.log('stats ', window.innerWidth);
  // console.log('noOutData', noOutData);

  useEffect(() => {
    getTranslations().then((retrievedTranslation) => {
      setTranslations(retrievedTranslation);
    });

    getStats().then((retrievedStats) => {
      setStats(retrievedStats);
      const { avgRating, avgScoreNoOut, stdDevNoOut } = retrievedStats;
      const newArray = [
        { name: 'Rating Medio', ...avgRating },
        { name: 'Score Medio', ...avgScoreNoOut },
        { name: 'Deviazione Standard', ...stdDevNoOut },
      ];
      setAvgData(newArray);

      const { stdDevSroiProponentNoOut, avgProponentSroiNoOut } = retrievedStats;
      function getValueByProponent(array, proponent) {
        const item = array.find((item) => item.proponent === proponent);
        return item ? item.value : 0;
      }

      // Create a new array of objects with avgValue and stdDevValue
      const proponentArray = avgProponentSroiNoOut.map((item) => {
        const proponent = item.proponent;
        const avgValue = item.value;
        const stdDevValue = getValueByProponent(stdDevSroiProponentNoOut, proponent);
        return { proponent, avgValue, stdDevValue };
      });
      setNoOutData(proponentArray);

      setDataBenefits(
        retrievedStats.totalProponentBenefits
          .filter((propBen) => propBen.proponent !== 'portfolio')
          .sort((propBenA, propBenB) => propBenA.value - propBenB.value)
          .map((propBen) => ({
            axis: optionsLabel
              .filter((opt) => opt.value === propBen.proponent)
              .map((filteredOpt) => filteredOpt.label)
              .join(),
            value: propBen.value,
          }))
      );
      setAvgBenefits(
        retrievedStats.stdDevSroiProponent
          .filter((propSroi) => {
            return propSroi.proponent !== 'portfolio';
          })
          .sort((propSroiA, propSroiB) => propSroiA.value - propSroiB.value)
          .map((propSroi) => ({
            axis: optionsLabel
              .filter((opt) => opt.value === propSroi.proponent)
              .map((filteredOpt) => filteredOpt.label)
              .join(),
            value: propSroi.value < 0 ? 0 : propSroi.value,
            type: 'avg',
          }))
      );

      setDataSroi(
        retrievedStats.avgProponentSroi
          .filter((propSroi) => {
            return propSroi.proponent !== 'portfolio';
          })
          .sort((propSroiA, propSroiB) => propSroiA.value - propSroiB.value)
          .map((propSroi) => ({
            axis: optionsLabel
              .filter((opt) => opt.value === propSroi.proponent)
              .map((filteredOpt) => filteredOpt.label)
              .join(),
            value: propSroi.value < 0 ? 0 : propSroi.value,
          }))
      );
      // setDataSroi(
      //   retrievedStats.avgProponentSroi
      //     .filter((propSroi) => propSroi.proponent !== 'portfolio')
      //     .filter((el) => !(el.proponent === 'UNI' && el.value === 0))
      //     .sort((propSroiA, propSroiB) => propSroiA.value - propSroiB.value)
      //     .map((propSroi) => ({
      //       axis: optionsLabel
      //         .filter((opt) => opt.value === propSroi.proponent)
      //         .map((filteredOpt) => filteredOpt.label)
      //         .join(),
      //       value: propSroi.value < 0 ? 0 : propSroi.value,
      //     }))
      // );

      setScoreEsg(
        Number(retrievedStats.avgPortfolioValue).toLocaleString('it-IT', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })
      );

      if (Object.keys(retrievedStats).length > 0) {
        setDataRating(retrievedStats.esgOutlook.percentage);
        //setDataRisk(retrievedStats.esgRisk === 0 ? {empty: 100} : retrievedStats.esgRisk);
        //setDataMateriality({empty: 100}); //retrievedStats.materiality
      }
    });

    /* getProjects().then((retrievedProjects) => {
      setProjects(retrievedProjects);
    }); */

    getInterventions().then((retrievedInterventions) => {
      setInterventions(retrievedInterventions);
    });

    getFinancings().then((retrievedFinancings) => {
      setFinancing(retrievedFinancings);
    });

    getStructureClusters().then((retrievedCluster) => {
      setCluster(retrievedCluster);
    });
  }, [projects]);

  useEffect(() => {
    setOptionsPagination([
      <Fragment key='options'>
        <option defaultValue='20' key='20'>
          20
        </option>
        <option value='50' key='50'>
          50
        </option>
        <option value='100' key='100'>
          100
        </option>
      </Fragment>,
    ]);

    if (stats.avgPortfolio) {
      setAvgRating(stats.avgPortfolio);
    } else if (projects && projects.length > 0) {
      const arrayOfRatings = projects
        .filter((proj) => proj.status === 'SUBMITTED')
        .map((filt) =>
          filt.esgratings.filter((rat) => rat.type === 'total').map((filt) => filt.score)
        )
        .flat();

      const sum = arrayOfRatings.reduce((a, b) => a + b, 0);
      const avg = sum / arrayOfRatings.length ?? 0;

      let rating = '';

      if (avg < 20) {
        rating = 'C';
      } else if (avg >= 20 && avg < 30) {
        rating = 'B';
      } else if (avg >= 30 && avg < 40) {
        rating = 'BB';
      } else if (avg >= 40 && avg < 50) {
        rating = 'BBB';
      } else if (avg >= 50 && avg < 60) {
        rating = 'A';
      } else if (avg >= 60 && avg < 70) {
        rating = 'A+';
      } else if (avg >= 70 && avg < 80) {
        rating = 'AA';
      } else if (avg >= 80 && avg < 90) {
        rating = 'AA+';
      } else if (avg >= 90 && avg <= 100) {
        rating = 'AAA';
      }

      setAvgRating(rating);
    }
  }, [projects, stats]);

  useEffect(() => {
    if (refresh) {
      getProjects().then((retrievedProjects) => {
        if (containsAzienda) {
          setProjects(retrievedProjects.filter((project) => project.owner !== 0));
        } else {
          setProjects(retrievedProjects);
        }
      });
      setRefresh(false);
    }
  }, [refresh, containsAzienda]);

  useEffect(() => {
    functionDrawRadarGradient();
  }, [dataBenefits]);

  useEffect(() => {
    if (isLoading) {
      exportExcel().then(() => {});
      setIsLoading(false);
    }
  }, [isLoading]);

  const functionDrawRadarGradient = (data, secondData, color, secondColor, divId, maxValue) => {
    const axisIndexMap = {};
    data?.forEach((item, index) => {
      axisIndexMap[item.axis] = index;
    });

    secondData?.sort((a, b) => {
      const indexA = axisIndexMap[a.axis];
      const indexB = axisIndexMap[b.axis];
      return indexA - indexB;
    });
    const mergedData = data && secondData && [...data, ...secondData];
    // console.log('data', data);
    const highestValue = mergedData && Math.max(...mergedData?.map((el) => el.value));
    return (
      <RadarChartGradient
        data={[data, secondData]}
        radarColor={color}
        secondRadarColor={secondColor}
        divId={divId}
        maxValue={highestValue}
      />
    );
  };

  const onFilterTextBoxChanged = useCallback((e) => {
    gridRef.current.api.setQuickFilter(e.target.value);
  }, []);

  const onGridReady = useCallback(() => {
    getProjects().then((retrievedProjects) => {
      //TEST
      if (containsAzienda) {
        setProjects(retrievedProjects.filter((project) => project.owner !== 0));
      } else {
        setProjects(retrievedProjects);
      }
    });
  }, [containsAzienda]);

  const onFirstDataRendered = useCallback((params) => {
    if (window.innerWidth >= 2540) {
      //&& window.screen.height >= 768
      // Resolution is 3840 //x768 or above
      gridRef.current.api.sizeColumnsToFit();
    } else {
      autoSizeAll(false);
    }
  }, []);
  const autoSizeAll = useCallback((skipHeader) => {
    const allColumnIds = [];
    gridRef.current.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }, []);

  const onModelUpdated = useCallback((params) => {
    if (window.innerWidth >= 2540) {
      //&& window.screen.height >= 768
      // Resolution is 3840 //x768 or above
      gridRef.current.api.sizeColumnsToFit();
    } else {
      autoSizeAll(false);
    }
  }, []);

  const onPageSizeChanged = useCallback(() => {
    let value = document.getElementById('page-size').value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  //   const onVirtualRowRemoved = useCallback(e => {
  //     /* if (window.screen.width >= 3840) { //&& window.screen.height >= 768
  //       // Resolution is 3840 //x768 or above
  //       gridRef.current.api.sizeColumnsToFit();
  //     } else {
  //       const allColumnIds = [];
  //       gridRef.current.columnApi.getAllColumns().forEach((column) => {
  //         allColumnIds.push(column.getId());
  //       });
  //       gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
  //     } */

  //     const allColumnIds = [];
  //       gridRef.current.columnApi.getAllColumns().forEach((column) => {
  //          allColumnIds.push(column.getId());
  //       });
  //       gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
  //   }, []);

  const jsxInCell = (params) => {
    return (
      <div className='d-flex justify-content-between align-items-center'>
        {params.data.requestId} <TooltipInfoIcon style={{ width: '1rem', minWidth: '1rem' }} />
      </div>
    );
  };

  const defaultColDef = {
    resizable: true,
    wrapText: true,
    autoHeight: true,
    flex: 1,
    suppressMovable: true,
    cellStyle: { padding: '0 0.7rem' },
  };

  const columnDefs = [
    {
      headerName: translations
        .filter((trans) => trans.code === 'ICS002')
        .map((trans) => {
          return trans.text;
        }),
      field: 'clientId',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: translations
        .filter((trans) => trans.code === 'ICS003')
        .map((trans) => trans.text),
      field: 'requestId',
      filter: 'agTextColumnFilter',
      tooltipField: 'description',
      //tooltipComponentParams: { color: 'red' }, //not work
      cellRendererSelector: () => {
        const idAndSvg = {
          component: jsxInCell,
        };

        return idAndSvg;
      },
    },
    {
      headerName: translations
        .filter((trans) => trans.code === 'ICS005')
        .map((trans) => trans.text),
      field: 'proponents',
      filter: 'agTextColumnFilter',
      valueGetter: (params) => {
        return params.data.proponents.name;
      },
    },
    // {
    //   headerName: translations
    //     .filter((trans) => trans.code === 'ICS033')
    //     .map((trans) => trans.text),
    //   field: 'interventionClassCode',
    //   filter: 'agTextColumnFilter',
    //   //minWidth: 200,
    //   valueGetter: (params) => {
    //     const interventionName = params.data.interventionClassCode
    //       ? interventions
    //           .filter((intervention) => intervention.code === params.data.interventionClassCode)
    //           .map((filteredIntervention) => filteredIntervention.text)
    //       : financing
    //           .filter((financing) => financing.code === params.data.financingTypeCode)
    //           .map((filteredFinancing) => filteredFinancing.text);

    //     return interventionName;
    //   },
    // },
    {
      headerName: translations
        .filter((trans) => trans.code === 'ICS008')
        .map((trans) => trans.text),
      field: 'sector',
      filter: 'agTextColumnFilter',
      valueGetter: (params) => {
        const sectorName = params.data.structureClusterCode
          ? cluster
              .filter((cluster) => cluster.code === params.data.structureClusterCode)
              .map((filteredCluster) => `${params.data.sectors.text}`)
          : params.data.sectors.text;
        return sectorName;
      },
    },
    {
      headerName: translations
        .filter((trans) => trans.code === 'ICS009')
        .map((trans) => trans.text),
      field: 'regions',
      filter: 'agTextColumnFilter',
      valueGetter: (params) => {
        return params.data.locations.nomeRegione;
      },
    },

    {
      // headerName: translations
      //   .filter((trans) => trans.code === 'ICS095')
      //   .map((trans) => trans.text),
      headerName: translations
        .filter((trans) => trans.code === 'ICS095')
        .map((trans) => 'Fatturato'),
      field: 'investment',
      cellStyle: { textAlign: 'right' },
      valueGetter: (params) => {
        if (params.data.interventionClassCode !== 'NOINTPURCH') {
          const totalCapex = params.data.sroiratings
            .filter((esgrating) => esgrating.code === 'TOTALCAPEX')
            .map((filteredRating) =>
              filteredRating.value.toLocaleString('it-IT', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            );
          return totalCapex.length > 0 ? `${totalCapex} €` : '';
        } else {
          return params.data?.totalInvestment
            ? `${params.data.totalInvestment.toLocaleString('it-IT', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} €`
            : '';
        }
      },
    },
    {
      headerName: translations
        .filter((trans) => trans.code === 'ICS053')
        .map((trans) => trans.text),
      field: 'esgratings',
      cellStyle: { textAlign: 'center' },
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains', 'equals'],
        defaultOption: 'equals',
      },
      valueGetter: (params) => {
        const rating = params.data.esgratings
          .filter((esgrating) => esgrating.type === 'total')
          .map((filteredRating) => filteredRating.rating);

        return rating;
      },
    },
    // {
    //   headerName: translations
    //     .filter((trans) => trans.code === 'ICS096')
    //     .map((trans) => trans.text),
    //   field: 'sroiratings',
    //   filter: 'agTextColumnFilter',
    //   cellStyle: { textAlign: 'right' },
    //   valueGetter: (params) => {
    //     // console.log('PARAMS:', params.data.sroiratings.find((sroi) => sroi.code === 'SROI')?.value);
    //     if (params.data.interventionClassCode !== 'NOINTPURCH') {
    //       return params.data.sroiratings
    //         .filter((sroi) => sroi.code === 'SROI')
    //         ?.map((filteredValue) =>
    //           filteredValue.value.toLocaleString('it-IT', {
    //             minimumFractionDigits: 2,
    //             maximumFractionDigits: 2,
    //           })
    //         );
    //     } else {
    //       return 'N.A.';
    //     }
    //   },
    // },
    {
      /*PINNATO A DESTRA*/
      headerName: translations
        .filter((trans) => trans.code === 'ICS052')
        .map((trans) => trans.text),
      field: 'status',
      cellStyle: (params) =>
        params.data.status !== 'DRAFT'
          ? { color: '#08a2aa', fontWeight: 'bold', padding: '0 0.7rem' }
          : { color: 'black', padding: '0 0.7rem' },
      pinned: 'right',
      filter: 'agTextColumnFilter',
      // minWidth: 100,
      valueGetter: (params) => {
        const status = params.data.status;
        let statusLabel;
        if (status === 'DRAFT') {
          statusLabel = 'Bozza';
        } else {
          statusLabel = 'Inviato';
        }
        return statusLabel;
      },
    },
    {
      headerName: '',
      pinned: 'right',
      minWidth: 170,
      maxWidth: 200,
      cellStyle: { textAlign: 'center' },
      cellRenderer: ActionBtnRenderer,
      cellRendererParams: {
        setRefresh: setRefresh,
        isCompany: true,
      },
    },
  ];

  const handleDownload = () => {
    setIsLoading(true);
  };

  const downloadTooltip = (props) => <Tooltip {...props}>Download Excel</Tooltip>;

  return (
    <>
      <div className='dashboard'>
        {/* primo blocco */}

        {/* <Col className='simple-box report-1 mt-0'>
          <Row>
            <h5 className='title-simple-box text-uppercase mt-1 pl-3'>Riepilogo</h5>
          </Row>
          <Row className='mt-3'>
            <Col className='text-center my-3'>
              <p className='text-capitalize'>numerosità</p>
              <p className='my-4 font-lg-2'>{stats.projectsSubmitted}</p>
              <p className='text-uppercase f-500 min-label-width'>progetti</p>
            </Col>
            <Col className='text-center my-3'>
              <p className='text-capitalize'>euro</p>
              <p className='my-4 font-lg-2'>
                {stats.totalInvestments?.toLocaleString('it-IT', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </p>
              <p className='text-uppercase f-500 min-label-width position-relative'>
                {translations.filter((trans) => trans.code === 'ICS043').map((trans) => trans.text)}
                <OverlayTrigger
                  placement='bottom'
                  delay={{ show: 200, hide: 400 }}
                  overlay={
                    <Tooltip id='button-tooltip'>
                      {translations
                        .filter((trans) => trans.code === 'ICS043')
                        .map((trans) => trans.description)}
                    </Tooltip>
                  }>
                  <TooltipInfoIcon
                    className='tooltip-ico'
                    style={{
                      width: '1rem',
                      position: 'absolute',
                      top: '50%',
                      transform: 'translateY(-50%)',
                    }}
                  />
                </OverlayTrigger>
              </p>
            </Col>
            <Col className='text-center my-3'>
              <p className='text-capitalize'>euro</p>
              <p className='my-4 font-lg-2'>
                {stats.avgInvestment?.toLocaleString('it-IT', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </p>
              <p className='text-uppercase f-500 min-label-width position-relative'>
                {translations.filter((trans) => trans.code === 'ICS044').map((trans) => trans.text)}
                <OverlayTrigger
                  placement='bottom'
                  delay={{ show: 200, hide: 400 }}
                  overlay={
                    <Tooltip id='button-tooltip'>
                      {translations
                        .filter((trans) => trans.code === 'ICS044')
                        .map((trans) => trans.description)}
                    </Tooltip>
                  }>
                  <TooltipInfoIcon
                    className='tooltip-ico'
                    style={{
                      width: '1rem',
                      position: 'absolute',
                      top: '50%',
                      transform: 'translateY(-50%)',
                    }}
                  />
                </OverlayTrigger>
              </p>
            </Col>
            <Col className='text-center my-3'>
              <p className='text-capitalize'>anni</p>
              <p className='my-4 font-lg-2'>
                {stats.avgDuration?.toLocaleString('it-IT', { maximumFractionDigits: 1 })}
              </p>
              <p className='text-uppercase f-500 min-label-width'>durata media</p>
            </Col>
          </Row>
        </Col> */}

        {/* secondo blocco */}
        <Row className='report-first gap-3 p-0 d-flex to-break'>
          {/* secondo blocco sinistra */}
          <Col className='simple-box justify-content-center align-items-center text-center margins-reset-bottom'>
            <Row className='ml-0'>
              {/*TODO*/}
              <h5 className='title-simple-box text-uppercase mt-1 pl-0'>
                {translations.filter((trans) => trans.code === 'ICS126').map((trans) => trans.text)}
              </h5>
            </Row>

            {dataRating ? (
              <>
                <Row className='mt-5 text-center'>
                  <Col id='donut-ratingEsg'>
                    <AnimatedDonutChart
                      chartId='ratingEsg'
                      data={dataRating}
                      scoreEsg={scoreEsg}
                      // scoreEsgNoOut={avgData
                      //   ?.filter((el) => el.name === 'Score Medio')
                      //   .map((esg) => esg.ESG)}
                    />
                    <p className='text-uppercase f-500 min-label-width mt-4 position-relative'>
                      {translations
                        .filter((trans) => trans.code === 'ICS045')
                        .map((trans) => trans.text)}
                      <OverlayTrigger
                        placement='bottom'
                        delay={{ show: 200, hide: 400 }}
                        overlay={
                          <Tooltip id='button-tooltip'>
                            {translations
                              .filter((trans) => trans.code === 'ICS045')
                              .map((trans) => trans.description)}
                          </Tooltip>
                        }>
                        <TooltipInfoIcon
                          className='tooltip-ico'
                          style={{
                            width: '1rem',
                            position: 'absolute',
                            top: '50%',
                            transform: 'translateY(-50%)',
                          }}
                        />
                      </OverlayTrigger>
                    </p>
                  </Col>

                  <Col id='donut-legend' className='align-self-start'>
                    <Row className='flex-column align-content-center'>
                      <Row className='align-items-center'>
                        <span>Rating:</span>
                      </Row>

                      <Row className='min-label-width align-items-center'>
                        <div className='box green'></div>

                        <div className='legend-text'>
                          A+ <span>|</span> AA <span>|</span> AA+ <span>|</span> AAA
                          <br />
                          (score 60-100)
                        </div>
                      </Row>

                      <Row className='min-label-width align-items-center'>
                        <div className='box yellow'></div>
                        <div className='legend-text'>
                          BB <span>|</span> BBB <span>|</span> A
                          <br />
                          (score 30-60)
                        </div>
                      </Row>

                      <Row className='min-label-width align-items-center'>
                        <div className='box red'></div>
                        <div className='legend-text'>
                          C <span>|</span> B
                          <br />
                          (score 0-30)
                        </div>
                      </Row>
                    </Row>
                  </Col>

                  <Col id='media-portafoglio' className='text-center justify-content-center'>
                    <div style={{ width: 'fit-content', margin: '0 auto' }}>
                      {/* <p className="text-capitalize">&nbsp;</p> */}
                      <p className='mb-4 font-lg-2'>{avgRating === '' ? 'N/A' : avgRating}</p>
                      <p className='media-text text-uppercase f-500'>media portafoglio</p>
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <Spinner animation='border' role='status' className='mt-5'>
                <span style={{ visibility: 'hidden' }}>Loading...</span>
              </Spinner>
            )}
          </Col>
          <Col
            className={`simple-box d-flex flex-column justify-content-center margins-reset-bottom `}>
            <Row className='ml-0'>
              {/*TODO*/}
              <h5 className='title-simple-box text-uppercase mt-1 pl-0'>
                {translations.filter((trans) => trans.code === 'ICS128').map((trans) => trans.text)}
              </h5>
            </Row>

            <Col
              style={{ marginLeft: '-15px' }}
              className='sroi-details-padding d-flex justify-content-center align-items-center'>
              <Col className='padding-to-fit' style={{ minWidth: '500px' }}>
                <Row id='sroi-details' style={{ minWidth: '320px' }}>
                  <Col style={{ maxWidth: '200px' }}>
                    <Row className={'border-bottom'}>
                      {/* benefici economici */}
                      <Col
                        className='f-600 basis-fit-content pr-0 no-to-head w-max-content'
                        style={{ visibility: 'hidden' }}>
                        ESG TABLE
                      </Col>
                    </Row>
                    {avgData &&
                      avgData.map((el, i) => {
                        return (
                          <Row
                            key={`${el.name}${i}`}
                            className={`${i + 1 !== avgData.length ? 'border-bottom' : null}`}>
                            <Col className='f-600 basis-fit-content pr-0 no-to-head w-max-content'>
                              {el.name}
                            </Col>
                          </Row>
                        );
                      })}
                  </Col>

                  <Col>
                    {/* <Row>
                           <Col className="text-right basis-fit-content pl-0">Medio</Col>
                        </Row> */}
                    <Row className={'border-bottom'}>
                      <Col className='text-center basis-fit-content pl-0 d-flex justify-content-between'>
                        {esgLabel.map((el, i) => {
                          return (
                            <Fragment key={`${el}${i}`}>
                              <ListItem
                                value={el}
                                style={{ maxWidth: '70px', fontWeight: 'bold', fontSize: '1rem' }}
                              />
                              {i + 1 !== esgLabel.length ? (
                                <span>
                                  <>| </>
                                </span>
                              ) : (
                                <span>
                                  <></>
                                </span>
                              )}
                            </Fragment>
                          );
                        })}
                      </Col>
                    </Row>
                    {/* DETTAGLIO ESG */}

                    {avgData &&
                      avgData.map((el, i) => {
                        return (
                          <Row
                            key={`${el.E}${el.S}`}
                            className={`${i + 1 !== avgData.length ? 'border-bottom' : null}`}>
                            {/* benefici economici */}
                            <Col className='text-center basis-fit-content pl-0 d-flex justify-content-between'>
                              <>
                                <ListItem value={el.E} style={{ maxWidth: '70px' }} />
                                <span>{<>| </>}</span>
                              </>
                              <>
                                <ListItem value={el.S} style={{ maxWidth: '70px' }} />
                                <span>{<>| </>}</span>
                              </>
                              <>
                                <ListItem value={el.G} style={{ maxWidth: '70px' }} />
                                <span>{<>| </>}</span>
                              </>
                              <>
                                <ListItem value={el.ESG} style={{ maxWidth: '70px' }} />
                                <span>{<></>}</span>
                              </>
                            </Col>
                          </Row>
                        );
                      })}
                  </Col>
                </Row>
              </Col>
            </Col>
            <div className='d-flex justify-content-end'>
              <span style={{ fontSize: '.65rem' }}>
                {' '}
                {translations
                  .filter((trans) => trans.code === 'ICS130')
                  .map((trans) => trans.text.toUpperCase())}
              </span>
            </div>
          </Col>
        </Row>
      </div>

      {/*  //progetti */}
      <div className='simple-box scenaritab'>
        <div className='row d-flex align-items-center mb-3'>
          <div className='col-6'>
            <h5 className='title-simple-box'>Esplora aziende</h5>
          </div>
          <div className='col-6 text-right'>
            <ButtonToolbar className='justify-content-end align-items-stretch'>
              <Button variant='info' href='/valutazione/azienda' className='lineheight-initial'>
                <Row className='align-items-center py-1 px-3'>
                  <IcoPlus className='light-color ico-small plus mr-1' />
                  <span className='d-md-inline'>Nuova Azienda</span>
                </Row>
              </Button>
              <OverlayTrigger placement='top' overlay={downloadTooltip}>
                <Button
                  variant='info'
                  onClick={!isLoading ? handleDownload : null}
                  aria-label='Download Excel'
                  className='ml-2 lineheight-initial'
                  disabled={isLoading}>
                  <Row
                    className='align-items-center justify-content-center'
                    style={{ padding: '0.25rem 0.7rem' }}>
                    {isLoading ? (
                      'Loading…'
                    ) : (
                      <DownloadFile width='23px' className='light-color m-0' />
                    )}
                  </Row>
                </Button>
              </OverlayTrigger>
              <InputGroup className='search-table align-items-center lineheight-initial'>
                <FormControl
                  type='text'
                  id='filter-text-box'
                  placeholder='Search...'
                  className='d-none d-md-block '
                  onInput={(e) => onFilterTextBoxChanged(e)}
                />
                <Lens />
              </InputGroup>
            </ButtonToolbar>
          </div>
        </div>
        <div className='row'>
          <div className='col-12' id='grid-wrapper'>
            {/* Bug warning of aria-description is fixed in react v18 */}
            {Object.keys(stats).length > 0 ? (
              <div className='ag-theme-alpine projects-list-table'>
                <AgGridReact
                  ref={gridRef}
                  columnDefs={columnDefs}
                  rowData={projects}
                  defaultColDef={defaultColDef}
                  domLayout='autoHeight'
                  tooltipShowDelay={0}
                  tooltipHideDelay={2000}
                  tooltipMouseTrack={true}
                  onFirstDataRendered={onFirstDataRendered}
                  //onVirtualRowRemoved={onVirtualRowRemoved}
                  //onColumnResized={onColumnResized}
                  //onGridSizeChanged={onGridSizeChanged}
                  onGridReady={onGridReady}
                  // autoSizeStrategy={{ type: 'fitCellContents' }}
                  onModelUpdated={onModelUpdated}
                  pagination={true}
                  paginationPageSize={20}
                  headerHeight={50}
                  rowHeight={50}
                  localeText={localeText}
                />
                <div className='example-header'>
                  Progetti per pagina:&nbsp;
                  <select onChange={onPageSizeChanged} id='page-size'>
                    {optionsPagination}
                  </select>
                </div>
              </div>
            ) : (
              <div className='text-center my-4'>
                <Spinner animation='border' role='status'>
                  <span style={{ visibility: 'hidden' }}>Loading...</span>
                </Spinner>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectsCompanyTab;
